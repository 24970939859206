.MainNewsletter-newsletter {
  padding-bottom: 100px;
  position: relative; }

.MainNewsletter-newsletterContent {
  max-width: calc(100vw - 30px);
  margin: auto; }

.MainNewsletter-newsletterInput {
  font-size: 16px;
  color: #7e7e7b;
  height: 48px;
  border: 2px solid #dcd4ce;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 20px; }

.MainNewsletter-inputGroup {
  margin-top: 36px;
  margin-bottom: 26px; }

.MainNewsletter-newsletterButton {
  border: 2px solid #dcd4ce;
  border-left-color: #dcd4ce;
  border-left-style: solid;
  border-left-width: 2px;
  border-left: none;
  color: #E8452C;
  font-weight: bold;
  background-color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0px 20px;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important; }
  .MainNewsletter-newsletterButton:hover, .MainNewsletter-newsletterButton:focus {
    color: #E8452C;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
    border-color: #dcd4ce;
    border-left-color: #dcd4ce; }

.MainNewsletter-newsletterLabel {
  position: relative;
  padding-left: 27px; }
