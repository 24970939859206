@charset "UTF-8";
.HowTo-number {
  color: #EB3F2E;
  font-weight: bold;
  width: 32px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 75px;
  margin-right: 15px; }

.HowTo-middleSquareOne {
  position: absolute;
  margin-top: 160px;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  z-index: 10; }

.HowTo-middleSquareTwo {
  position: absolute;
  margin-top: 320px;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  z-index: 10; }

.HowTo-middleSquareThree {
  position: absolute;
  margin-top: 480px;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  z-index: 10; }

.HowTo-middleSquareFour {
  position: absolute;
  margin-top: 640px;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  z-index: 10; }

.HowTo-arrowSymbol {
  position: absolute;
  margin-top: 800px;
  margin-left: 0.3px;
  color: #EB3F2E;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  z-index: 10; }

.HowTo-middleLine {
  color: white;
  font-weight: bold;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 15px;
  z-index: 10; }
  .HowTo-middleLine:after {
    content: "";
    position: absolute;
    height: 99%;
    border: 2px dashed #EB3F2E;
    z-index: -2;
    top: 33px; }

.HowTo-🪞 p {
  line-height: 1.41;
  font-size: inherit;
  margin: inherit; }
