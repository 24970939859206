.TagsList {
  min-width: 100%;
  z-index: 1; }
  .TagsList .multi-select__control {
    margin-bottom: 20px;
    border-color: #DBD0C9; }
    .TagsList .multi-select__control:hover {
      border-color: #DBD0C9;
      box-shadow: none; }
    .TagsList .multi-select__control--is-focused {
      border-color: #DBD0C9;
      box-shadow: none; }
    .TagsList .multi-select__control .multi-select__value-container {
      font-size: 1.3rem;
      padding-left: 10px; }
    .TagsList .multi-select__control .multi-select__dropdown-indicator {
      display: none !important; }
    .TagsList .multi-select__control .multi-select__indicator-separator {
      display: none !important; }
    .TagsList .multi-select__control .multi-select__multi-value {
      background-color: #4B8795;
      color: white;
      border-radius: 20px; }
    .TagsList .multi-select__control .multi-select__multi-value__label {
      color: white;
      padding: 3px 15px;
      padding-right: 3px;
      text-transform: lowercase;
      font-size: 16px; }
      .TagsList .multi-select__control .multi-select__multi-value__label:first-letter {
        text-transform: uppercase; }
    .TagsList .multi-select__control .multi-select__multi-value__remove {
      background-color: #4B8795;
      color: white;
      padding-right: 10px;
      cursor: pointer;
      border-radius: 20px;
      position: relative;
      z-index: 1000000; }
  .TagsList .tag {
    z-index: 99; }
