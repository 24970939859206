.FundingNewsTab {
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  border-radius: 10px;
  background-color: white;
  padding: 10px 20px;
  border: 1px solid #38675B;
  overflow-wrap: break-word; }
  .FundingNewsTab-icon {
    height: 20px;
    cursor: pointer; }
  .FundingNewsTab-icon--edit {
    height: 18px;
    margin-right: 20px; }
  .FundingNewsTab-spacing--sm {
    height: 15px; }
  .FundingNewsTab-spacing--md {
    height: 25px; }

.FundingNewsTab-authors {
  display: block; }

.FundingNewsTab-author {
  display: flex; }

.FundingNewsTab-authorAvatar {
  width: 32px;
  height: 32px; }

.FundingNewsTab-authorName {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px; }
