.Alert {
  border-radius: 24px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
  overflow: hidden; }
  .Alert-header {
    background-color: #C9E0FC;
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .Alert-header--danger {
    background-color: #E7535F; }
  .Alert-header--success {
    background-color: #73E494; }
  .Alert-body {
    font-size: 20px;
    line-height: 24px;
    padding: 48px 50px 34px 50px;
    background-color: white;
    text-align: center; }
  .Alert-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 34px; }
