.carousel .carousel-control {
  background: none;
  opacity: .7; }
  .carousel .carousel-control:focus, .carousel .carousel-control:hover {
    opacity: .9; }
  .carousel .carousel-control:active {
    opacity: 1; }
  .carousel .carousel-control span {
    color: #4B8795; }

.carousel .carousel-inner .item {
  padding: 0 120px; }

.carousel .carousel-indicators {
  bottom: -30px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%); }
  .carousel .carousel-indicators li {
    background-color: #a2a2a1;
    width: 10px;
    height: 10px;
    margin: 0;
    margin-left: 5px;
    border-radius: 50%; }
  .carousel .carousel-indicators li.active {
    background-color: #4B8795; }

@media (max-width: 767px) {
  .carousel .carousel-inner .item {
    padding: 0 50px; } }
