.NewsTab {
  padding: 24px 0; }
  .NewsTab-icon {
    height: 20px;
    cursor: pointer; }
  .NewsTab-icon--edit {
    height: 18px;
    margin-right: 20px; }
  .NewsTab-spacing--sm {
    height: 15px; }
