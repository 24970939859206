.MessagePage-boxContainer {
  background: #ffffff;
  border-radius: 23px;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  height: auto;
  width: 300px;
  margin: auto;
  display: block;
  padding: 15px 10px 30px 10px; }

.MessagePage-roleWrapper {
  display: flex;
  justify-content: space-around; }

.MessagePage-userWrapper {
  border-bottom: 1px solid lightgrey;
  margin: 0 30px; }

.MessagePage-roleContributorWrapper {
  display: flex;
  align-items: center;
  padding: 0 10px 10px 10px;
  justify-content: space-between; }

.MessagePage-roleContributorAligned {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px; }

.MessagePage-buttonWrapper {
  display: flex;
  justify-content: space-around; }

.MessagePage-buttonWrapperMob {
  display: flex;
  justify-content: flex-start; }

.MessagePage-buttonWrapperFlexed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px; }

.MessagePage-spacingMd {
  height: 50px; }

.MessagePage-infoLine {
  display: grid;
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  align-items: center;
  text-align: center;
  grid-gap: 20px;
  width: 100%; }
  .MessagePage-infoLine:before, .MessagePage-infoLine:after {
    content: '';
    border-top: 1px solid #7E7E7B; }
