.ShopRecap-item {
  box-shadow: 4px 4px 0 rgba(60, 60, 59, 0.16);
  border-radius: 24px;
  background-color: #ffffff;
  padding: 30px;
  margin-bottom: 25px;
  margin-top: 35px; }

.ShopRecap-emptyItem {
  box-shadow: 4px 4px 0 rgba(60, 60, 59, 0.16);
  border-radius: 24px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 25px;
  margin-top: 55px; }
