.Card-main {
  background: transparent url("./img/oval.svg");
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 264px;
  width: 264px;
  padding: 30px;
  text-align: center;
  color: #ffffff; }
