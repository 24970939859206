.justify-content-input-search {
  justify-content: center;
}

.search-input {
  outline: none;
  font-size: 1.3rem;
  color: #000;
  min-width: 80px;
  padding-top: 0;
  padding-right: 0;
  border-radius: 8px;

  input {
    width: calc(100% - 32px);
    border: 0;
    min-height: 25px;

    &:focus {
      border: 0;
      outline: none;
    }

    &::placeholder {
      overflow: visible;
    }
  }

  div {
    width: 32px;
    height: 32px;
    background-color: #fff;
    text-align: center;
    vertical-align: middle;

    border-left: 1px solid #ced4da;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;

    .position-hellper {
      height: 32px;
      width: 0;
      display: inline-block;
      border-left: none;
    }
  }

  &__search {
    padding-top: 5px;
  }

  &+span {
    position: relative;
    z-index: 2000;
    top: -1.7em;
    height: 0; // hack not to influence position of search input
    width: calc(100% - 32px);
    text-align: center;
  }

  & [class*="search-input__"] span:not(.fa) {
    width: 80px;
    position: relative;
    left: -1.4em;
    top: 0.3em;
    transform: scale(0.4);
  }
}

@media (min-width: 768px) {
  .justify-content-input-search {
    justify-content: right;
  }

  .search-input {
    // aby fungovalo klikání na křížek a na lupu
    position: relative;
    z-index: 1000;
  }
}

@media (min-width: 1200px) {
  .search-input {
    width: 150%;

    &+span {
      width: calc(150% - 32px);
    }
  }
}
