.FundingInfo {
  width: 100%;
  min-height: 110px; }
  .FundingInfo-line {
    height: 1px;
    width: 100%;
    background-color: #ececec;
    margin-top: 20px;
    margin-bottom: 20px; }
  .FundingInfo-spacing--xs {
    height: 5px; }
  .FundingInfo-spacing--sm {
    height: 14px; }
  .FundingInfo-spacing--lg {
    height: 30px; }
  .FundingInfo-spacing--xl {
    height: 40px; }
