.News {
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  border-radius: 24px;
  background-color: white;
  padding: 30px 20px; }
  .News-empty {
    text-align: center;
    padding: 23px 40px 0px 40px; }
  .News-spacing--sm {
    height: 20px; }
  .News-spacing--md {
    height: 24px; }
