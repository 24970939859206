.MessageListPage-spacingMd {
  height: 50px; }

.MessageListPage-dot {
  height: 8px;
  width: 8px;
  background-color: #E8452C;
  border-radius: 50%;
  position: absolute;
  right: 0; }

.MessageListPage-dotMobile {
  height: 8px;
  width: 8px;
  background-color: #E8452C;
  border-radius: 50%;
  position: absolute;
  right: 25px;
  margin-top: -30px; }
