.Blog-container {
  padding-top: 0; }

.Blog-wrapper {
  color: #38675B;
  background-color: #ffffff;
  font-size: 16px;
  border-radius: 25px;
  padding: 15px 15px 15px 0;
  margin: 20px 15px 0px 15px;
  min-height: 155px;
  max-height: 155px;
  transition: all 250ms ease; }
  .Blog-wrapper:hover {
    box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16); }

.Blog-info {
  width: 65%;
  margin-left: 35%;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 100px;
  overflow: hidden;
  margin-left: -20px; }

.Blog-photoSide {
  float: left;
  height: 155px;
  width: 100px;
  margin-top: -15px; }

.Blog-imgLeft {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 100%;
  width: 100%;
  object-fit: cover; }

.Blog-containerGroup {
  padding-top: 0; }

.Blog-wrapperGroup {
  color: #38675B;
  background-color: #ffffff;
  font-size: 16px;
  border-radius: 25px;
  padding: 15px 15px 15px 0;
  margin: 20px 15px 20px 15px;
  min-height: 155px;
  max-height: 155px;
  transition: all 250ms ease; }
  .Blog-wrapperGroup:hover {
    box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16); }

.Blog-infoGroup {
  width: 65%;
  margin-left: 35%;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 100px;
  overflow: hidden;
  margin-left: -10px; }

.Blog-photoSideGroup {
  float: left;
  height: 155px;
  width: 100px;
  margin-top: -15px; }

.Blog-imgLeftGroup {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 100%;
  width: 100%;
  object-fit: cover; }

.Blog-moreButton {
  padding-left: 10px; }

.Blog-moreButtonGroup {
  padding-left: 10px; }

@media (max-width: 360px) {
  .Blog-info {
    width: 55%;
    padding-left: 10px; }
  .Blog-infoGroup {
    width: 55%;
    padding-left: 10px; } }

@media (min-width: 1200px) {
  .Blog-container {
    padding-top: 15px; }
  .Blog-wrapper {
    color: #38675B;
    background-color: #ffffff;
    font-size: 16px;
    border-radius: 25px;
    padding: 15px 15px 15px 0;
    width: 100%;
    float: left;
    margin: 20px 15px 20px 0;
    min-height: 255px;
    max-height: 255px; }
  .Blog-info {
    width: 65%;
    margin-left: 35%;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    padding-left: 0; }
  .Blog-photoSide {
    height: 255px;
    width: 350px;
    margin-top: -15px;
    float: left; }
  .Blog-imgLeft {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .Blog-moreButton {
    padding-left: 0; }
  .Blog-containerGroup {
    display: flex;
    justify-content: space-between;
    margin-top: -25px; }
  .Blog-wrapperGroup {
    color: #38675B;
    background-color: #ffffff;
    font-size: 16px;
    border-radius: 25px;
    padding: 15px 15px 15px 0;
    width: 49.3%;
    margin: 0 0 20px 0;
    min-height: 255px;
    max-height: 255px;
    float: left; }
    .Blog-wrapperGroup:last-child {
      float: right; }
  .Blog-infoGroup {
    width: 65%;
    margin-left: 35%;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    padding-left: 15px; }
  .Blog-photoSideGroup {
    height: 255px;
    width: 175px;
    margin-top: -15px;
    float: left; }
  .Blog-imgLeftGroup {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .Blog-moreButtonGroup {
    padding-left: 15px; } }

@media not all and (min-resolution: 0.001dpcm) {
  .Blog-info {
    padding-left: 35px; }
  .Blog-infoGroup {
    padding-left: 25px; } }
