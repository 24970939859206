.Comment-main {
  padding: 25px 15px;
  background-color: white;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  border-radius: 24px;
  margin-top: 25px;
  margin-bottom: 25px; }

.Comment-mainMessenger {
  padding: 25px 15px;
  background-color: white;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  border-radius: 24px;
  margin-top: 25px;
  margin-bottom: 25px; }

.Comment-content {
  min-height: 80px; }

.Comment-sub {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #ececec; }

.Comment-avatar {
  padding-right: 15px; }

.Comment-avatarMessenger {
  padding-right: 15px; }

.Comment-mediabody {
  padding-left: 15px; }

.Comment-info {
  margin-bottom: 10px; }

.Comment .form-group {
  margin: 0; }

.Comment .Input--area {
  height: auto;
  max-height: 500px;
  min-height: 80px; }

@media (max-width: 767px) {
  .Comment-main {
    padding: 15px 10px 15px 0;
    margin-top: 15px;
    margin-bottom: 15px; }
  .Comment-mainMessenger {
    margin-top: 15px;
    margin-bottom: 15px; }
  .Comment-content {
    min-height: 0; }
  .Comment-sub {
    margin-top: 10px;
    padding-top: 10px; }
  .Comment-avatar {
    display: none;
    padding-right: 5px; }
  .Comment-avatarMessenger {
    padding-right: 5px; }
  .Comment-mediabody {
    padding-left: 20px; }
  .Comment-info {
    margin-bottom: 5px;
    font-size: 12px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .Comment-main {
    padding: 15px 15px;
    margin-top: 15px;
    margin-bottom: 15px; }
  .Comment-mainMessenger {
    padding: 15px 15px;
    margin-top: 15px;
    margin-bottom: 15px; }
  .Comment-content {
    min-height: 60px; }
  .Comment-sub {
    margin-top: 15px;
    padding-top: 15px; }
  .Comment-avatar {
    padding-right: 5px; }
    .Comment-avatar .Avatar {
      width: 64px;
      height: 64px; }
  .Comment-avatarMessenger {
    padding-right: 5px; }
    .Comment-avatarMessenger .AvatarMessenger {
      width: 64px;
      height: 64px; }
  .Comment-mediabody {
    padding-left: 10px; }
  .Comment-info {
    margin-bottom: 5px; }
    .Comment-info .Text {
      font-size: 14px; }
  .Comment .Input--area {
    min-height: 70px; } }
