.SoldRewardInfo {
  border-radius: 24px;
  border: 1px solid #38675b;
  padding: 25px;
  background-color: #b1b1b1;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  transition: all 250ms ease;
  justify-content: flex-start; }

.SoldRewardInfo-book {
  padding: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%; }

.SoldRewardInfo-button {
  flex-shrink: 0; }

.SoldRewardInfo-headline {
  display: flex;
  justify-content: space-between; }

.SoldRewardInfo .SoldRewardInfo-headline {
  height: auto;
  word-break: break-word; }

.SoldRewardInfo--book {
  background-color: #e8452c;
  justify-content: flex-start;
  cursor: default; }

.SoldRewardInfo--book:hover {
  background-color: #e8452c; }

.SoldRewardInfo--book .RewardInfo-headline {
  height: auto; }

.SoldRewardInfo-remaining {
  color: #7e7e7b; }

.SoldRewardInfo-spacing--xs {
  height: 5px; }

.SoldRewardInfo-spacing--sm {
  height: 15px; }

.SoldRewardInfo-spacing--md {
  height: 50px; }

.SoldRewardInfo-footerSection {
  border-top: 1px solid #38675b;
  margin-top: auto;
  padding-top: 25px; }

.SoldRewardInfo-background {
  border-radius: 0 0 24px 24px;
  padding: 10px 25px 12px 25px;
  margin: -25px; }

.SoldRewardInfo-background--financing {
  background-color: #328c69; }

@media (min-width: 1200px) {
  .SoldRewardInfo {
    width: 310px; } }
