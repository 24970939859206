.values {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.rangeslider-horizontal {
  height: 5px; }
  .rangeslider-horizontal .rangeslider__fill {
    background: #E8452C; }
  .rangeslider-horizontal .rangeslider__handle {
    background: #E8452C;
    border-color: #E8452C; }
  .rangeslider-horizontal .rangeslider__handle::after {
    background: #E8452C;
    border-color: #E8452C;
    box-shadow: none; }
