.tip-component {
  min-height: 110px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 15px 30px 15px 20px;
  background: rgba(50, 140, 105, 0.6) url(./img/background.svg);
  background-size: cover;
  border-radius: 125px;
  text-align: left;
  color: white; }
  .tip-component img {
    border-radius: 50%;
    margin-right: 20px;
    min-width: 60px;
    flex-shrink: 0; }

@media (max-width: 767px) {
  .tip-component {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    border-radius: 24px; } }
