.ConfirmEmail {
  text-align: center; }
  .ConfirmEmail-spacing--sm {
    height: 32px; }
  .ConfirmEmail-spacing--md {
    height: 37px; }
  .ConfirmEmail-spacing--lg {
    height: 52px; }
  .ConfirmEmail-spacing--xl {
    height: 60px; }
