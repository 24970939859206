.Logo {
  height: 30px;
  width: 164px; }

.Logo-logo {
  width: 100%; }

.Logo--small {
  height: 24px;
  width: 132px; }

@media (max-width: 991px) {
  .Logo--small {
    width: 100px;
    margin-right: 10px; } }

@media (max-width: 767px) {
  .Logo--small {
    width: 110px; } }
