.Bell {
  position: relative;
  display: flex;
  align-items: center; }
  .Bell-numberActive {
    min-width: 18px;
    height: 18px;
    position: absolute;
    background-color: #E8452C;
    border-radius: 9px;
    padding: 0 3px;
    border: 2px solid white;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    top: calc(50% - 14px);
    right: -8px; }
  .Bell-number {
    min-width: 18px;
    height: 18px;
    position: absolute;
    background-color: #DCD1C9;
    border-radius: 9px;
    padding: 0 3px;
    border: 2px solid white;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    top: calc(50% - 14px);
    right: -8px; }
