a.UserPanel-link, a.UserPanel-link:hover {
  text-decoration: none; }

.UserPanel {
  padding: 25px;
  background-color: white;
  border-radius: 24px;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  text-align: left;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 250ms ease; }
  .UserPanel.used {
    background-color: darkgrey;
    pointer-events: none; }
  .UserPanel:hover {
    background-color: #f7f7f7; }
  .UserPanel-head {
    display: flex;
    align-items: center;
    padding-bottom: 28px;
    border-bottom: 1px solid #ececec;
    flex-shrink: 0; }
  .UserPanel-subrole {
    padding-top: 21px;
    padding-bottom: 14px;
    border-bottom: 1px solid #ececec; }
    .UserPanel-subrole:last-child {
      border-bottom: 0; }
  .UserPanel-gallery {
    padding-top: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
  .UserPanel-imageStep {
    width: 15px; }
  .UserPanel-showMore {
    margin-top: 10px; }
  .UserPanel-button {
    padding-top: 15px; }
