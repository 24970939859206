.ShopCart-emptyItem {
  box-shadow: 4px 4px 0 rgba(60, 60, 59, 0.16);
  border-radius: 24px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 25px;
  margin-top: 55px; }

.ShopCart-plus {
  float: left;
  background-color: #E8452C;
  padding: 5px 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 1px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff; }

.ShopCart-symbolWrapper {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent; }
