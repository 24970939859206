.Avatar {
  width: 60px;
  height: 60px;
  max-width: 100%;
  max-height: 100%; }

.Avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover; }

.Avatar--medium {
  margin: 25px 0;
  width: 140px;
  height: 140px; }

.Avatar--big {
  width: 256px;
  height: 256px; }
  .Avatar--big .Avatar-img {
    box-shadow: 4px 4px 0 0 rgba(60, 60, 59, 0.16); }

.Avatar--small {
  width: 64px;
  height: 64px;
  flex-shrink: 0; }

.Avatar--xs {
  width: 40px;
  height: 40px;
  flex-shrink: 0; }

.Avatar--lg {
  width: 128px;
  height: 128px;
  flex-shrink: 0; }

.Avatar--md {
  width: 96px;
  height: 96px;
  flex-shrink: 0; }

.Avatar--xxs {
  width: 32px;
  height: 32px;
  flex-shrink: 0; }

.Avatar--center {
  margin: auto; }

.Avatar--onboarding {
  margin: auto;
  position: absolute;
  bottom: -69px;
  left: 50%;
  transform: translateX(-50%);
  width: 128px;
  height: 128px; }

.Avatar--border {
  border: 2px solid #ececec;
  border-radius: 50%; }

.Avatar--notif {
  border: 2px solid #ececec;
  border-radius: 50%;
  width: 40px;
  height: 40px; }

.Avatar--shadow .Avatar-img {
  box-shadow: 2px 2px 0 0 rgba(60, 60, 59, 0.16); }

.Avatar--navbar {
  border: 2px solid #ececec;
  border-radius: 50%;
  width: 36px;
  height: 36px; }
  .Avatar--navbar:after {
    content: "";
    position: absolute;
    right: -10px;
    background-image: url("/images/svg/arrow_down.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 8px;
    height: 10px;
    background-size: contain;
    top: 50%;
    transform: translateY(-50%); }

@media (max-width: 767px) {
  .Avatar--md {
    width: 64px;
    height: 64px; } }
