.main-layout {
  width: 100%;
  height: 100%;
  position: relative; }
  .main-layout .header {
    width: 100%;
    height: 50px;
    position: fixed; }

.alertify-notifier {
  z-index: 2051 !important;
  top: 90px !important;
  font-size: 1.4rem;
  color: white; }

.ajs-message {
  border-radius: 10px; }

.ajs-modal {
  z-index: 999999;
  top: 130px; }

.env-info {
  background: yellow;
  color: black;
  border: 2px solid black;
  border-radius: 5px;
  opacity: 0.8;
  position: fixed;
  right: 5px;
  bottom: 5px;
  padding: 5px;
  font-size: 25px;
  font-weight: bold;
  z-index: 1000000; }
