.NewsletterPopup-header {
  padding: 50px 20px 30px;
  text-align: center;
  max-width: 725px;
  margin: auto; }

.NewsletterPopup-form {
  padding: 15px 0 15px 48px; }

.NewsletterPopup-spacing--sm {
  height: 18px; }

.NewsletterPopup-imageBox {
  background-image: url("./img/bg.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  display: flex;
  align-items: flex-end;
  justify-content: center; }

.NewsletterPopup-bottom {
  display: flex; }

.NewsletterPopup-left {
  width: 45%; }

.NewsletterPopup-right {
  width: 55%; }

.NewsletterPopup .has-feedback label ~ .form-control-feedback {
  top: -45px;
  right: 30px;
  height: 0;
  width: 0;
  float: right;
  position: relative; }

@media (max-width: 767px) {
  .NewsletterPopup-bottom {
    flex-wrap: wrap; }
  .NewsletterPopup-right, .NewsletterPopup-left {
    width: 100%; }
  .NewsletterPopup-left {
    margin-bottom: 20px; }
  .NewsletterPopup-form {
    padding: 15px 20px; } }
