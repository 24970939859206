.PromoThanks {
  padding: 50px 20px 0 50px;
  display: flex; }
  .PromoThanks-spacing--xs {
    height: 15px; }
  .PromoThanks-spacing--sm {
    height: 25px; }
  .PromoThanks-imageBox {
    background-image: url("./img/bg.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center; }
  .PromoThanks-left {
    width: 45%;
    padding-top: 70px;
    padding-right: 20px; }
  .PromoThanks-right {
    width: 55%; }

@media (max-width: 767px) {
  .PromoThanks {
    flex-direction: column;
    text-align: center;
    padding: 25px 25px 0; }
    .PromoThanks-left {
      padding-bottom: 25px;
      width: 100%; }
    .PromoThanks-right {
      width: 100%; } }
