.ProjectDetailScreen-btnIcon {
  margin-right: 5px; }

.ProjectDetailScreen-box {
  border-radius: 12px;
  padding: 15px;
  box-shadow: 4px 4px 0 0 rgba(60, 60, 59, 0.16);
  background-color: #fff; }

.ProjectDetailScreen-rewardItem {
  padding-right: 0; }
  .ProjectDetailScreen-rewardItem:first-child {
    padding-right: 45px; }

.ProjectDetailScreen-acceptContainer {
  background: #ffffff;
  border-radius: 23px;
  height: auto;
  margin: auto;
  display: block;
  padding: 15px 10px 30px 10px; }

.ProjectDetailScreen-errorContainer {
  background: #E8452C;
  border-radius: 25px;
  height: 45px;
  width: 650px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; }

.ProjectDetailScreen-financing {
  padding: 0 40px;
  background-color: white;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  border-radius: 24px;
  margin-bottom: 25px;
  margin-top: 55px;
  display: flex;
  flex-direction: column; }

.ProjectDetailScreen-buttonContainer .Button svg, .ProjectDetailScreen-buttonContainer .Button svg path {
  fill: black;
  padding-right: 3px;
  transition: all 250ms; }

.ProjectDetailScreen-buttonContainer .Button:hover svg, .ProjectDetailScreen-buttonContainer .Button:hover svg path, .ProjectDetailScreen-buttonContainer .Button:focus svg, .ProjectDetailScreen-buttonContainer .Button:focus svg path {
  fill: white;
  transition: all 250ms; }

.ProjectDetailScreen-financingItem {
  padding: 35px 40px;
  border-top: 1px solid #ececec;
  position: relative;
  cursor: pointer;
  transition: all 125ms ease; }
  .ProjectDetailScreen-financingItem:before {
    content: "";
    width: 24px;
    height: 24px;
    border: 2px solid #ececec;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 36px;
    transition: all 125ms ease; }
  .ProjectDetailScreen-financingItem:after {
    content: none;
    width: 12px;
    height: 12px;
    background-color: #E8452C;
    border-radius: 50%;
    position: absolute;
    left: 6px;
    top: 42px;
    transition: all 125ms ease; }
  .ProjectDetailScreen-financingItem:hover:before {
    border-color: #fad3cd; }
  .ProjectDetailScreen-financingItem:hover:after {
    content: "";
    background-color: #fad3cd; }

.ProjectDetailScreen-financingItem--active:before {
  border-color: #E8452C; }

.ProjectDetailScreen-financingItem--active:after {
  content: ""; }

.ProjectDetailScreen-financingItem--disabled {
  filter: grayscale(100%);
  opacity: 0.6;
  pointer-events: none; }

.ProjectDetailScreen-financingItem--first {
  border-top: 0; }

.ProjectDetailScreen-genre:first-letter {
  text-transform: uppercase; }

.ProjectDetailScreen-rewardsBackground {
  background: #ffff;
  margin-left: -1500px;
  margin-right: -1500px;
  margin-top: -20px;
  margin-bottom: 0px;
  padding: 0 1500px 0px; }

.ProjectDetailScreen-footer {
  position: relative;
  margin-bottom: 6px;
  margin-top: 150px; }
  .ProjectDetailScreen-footer:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 900px;
    top: -270px;
    left: -50%;
    z-index: -1;
    transform: rotateY(180deg) rotateX(-50deg);
    background-image: url("./img/bg.svg");
    background-size: 1992px;
    background-repeat: no-repeat;
    background-position: 215px 20px; }

.ProjectDetailScreen-how {
  display: flex;
  align-items: center; }

.ProjectDetailScreen-howImage {
  margin-right: 20px;
  max-width: 75px;
  align-self: flex-start; }

.ProjectDetailScreen-newsPanel {
  padding: 30px 25px;
  background-color: white;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  border-radius: 24px; }

.ProjectDetailScreen-spacing--xs {
  height: 7px; }

.ProjectDetailScreen-spacing--semi {
  height: 10px; }

.ProjectDetailScreen-spacing--sm {
  height: 14px; }

.ProjectDetailScreen-spacing--lg {
  height: 30px; }

.ProjectDetailScreen-spacing--xl {
  height: 60px; }

.ProjectDetailScreen-genres,
.ProjectDetailScreen-tags {
  display: flex;
  margin: -4px;
  flex-wrap: wrap; }

.ProjectDetailScreen-genre,
.ProjectDetailScreen-tag {
  margin: 4px; }

.ProjectDetailScreen-activityButton {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0 0 0 8px;
  background: transparent;
  border: 0;
  color: #7E7E7B;
  text-decoration: none; }
  .ProjectDetailScreen-activityButton:hover {
    text-decoration: underline; }

.ProjectDetailScreen-authorTitle {
  color: #7E7E7B;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 16px; }

.ProjectDetailScreen-authors {
  display: block; }

.ProjectDetailScreen-author {
  display: flex;
  padding: 8px; }

.ProjectDetailScreen-authorFinancingWrapper {
  padding: 8px; }

.ProjectDetailScreen-authorReleasedWrapper {
  display: flex;
  align-items: center; }

.ProjectDetailScreen-authorFinancing {
  display: block;
  padding: 8px 8px 8px 0; }

.ProjectDetailScreen-authorFinancingExtended {
  display: block;
  padding: 50px 8px 8px 50px; }

.ProjectDetailScreen-authorAvatar {
  width: 40px;
  height: 40px; }

.ProjectDetailScreen-authorAvatarExtended {
  width: 120px;
  height: 120px; }

.ProjectDetailScreen-authorName {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #323038;
  margin-left: 8px;
  font-weight: bold; }

.ProjectDetailScreen-authorFinancingName {
  font-size: 16px;
  color: #323038;
  font-weight: bold; }

.ProjectDetailScreen-authorReleasedName {
  font-size: 16px;
  color: #323038;
  font-weight: bold;
  padding: 0 7px; }

@media (max-width: 767px) {
  .ProjectDetailScreen-how {
    margin-bottom: 15px; } }

@media (min-width: 1200px) {
  .ProjectDetailScreen-acceptContainer {
    background: #ffffff;
    border-radius: 23px;
    margin: auto;
    display: block;
    padding: 15px 30px 30px 30px;
    max-width: 820px; }
  .ProjectDetailScreen-donateButton {
    display: block;
    position: fixed;
    top: 10px; }
  .ProjectDetailScreen-authors {
    display: flex;
    flex-flow: wrap; }
  .ProjectDetailScreen-answer {
    min-width: 550px; } }

@media (max-width: 300px) {
  .d-block.d-sm-none .ProjectDetailScreen-financing {
    margin-left: -20px;
    width: 250px;
    padding-left: 10px;
    padding-right: 10px; } }
