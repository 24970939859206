.ContributionCard {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  height: calc(100% - 30px);
  transition: all 250ms ease;
  justify-content: flex-start;
  height: 260px;
  width: 280px; }
  @media (min-width: 1200px) {
    .ContributionCard {
      margin-left: 0;
      margin-bottom: 30px;
      margin-top: 0; } }

.ContributionCard--one {
  background: #fef9fa; }

.ContributionCard--two {
  background: #fbe9ea; }

.ContributionCard--three {
  background: #f5fafd; }

.ContributionCard--four {
  background: #f4f9f5; }

.ContributionCard--five {
  background: #ebf0ee; }

.ContributionCard--six {
  background: #eaf6f3; }

.ContributionCard--seven {
  background: #fff8f4; }

.ContributionCard--eight {
  background: #fff8f4; }

.ContributionCard--nine {
  background: #feeeec; }

.ContributionCard--ten {
  background: #faf3eb; }
  .ContributionCard--ten-bibliography {
    display: flex;
    justify-content: center;
    padding: 5px;
    margin-bottom: -44px; }
  .ContributionCard--ten-coverPhoto {
    max-width: 100%;
    width: auto;
    height: auto;
    max-width: 210px;
    display: flex;
    align-self: flex-start;
    margin-top: -5%; }
  .ContributionCard--ten-book {
    padding: 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%; }
  .ContributionCard--ten-button {
    flex-shrink: 0; }
  .ContributionCard--ten:hover {
    background-color: #f7f7f7; }
  .ContributionCard--ten-headline {
    display: flex;
    justify-content: center;
    text-align: center; }
  .ContributionCard--ten-progress {
    padding: 15px;
    margin-top: auto; }
  .ContributionCard--ten-progress--emptyNews {
    margin-top: auto; }
  .ContributionCard--ten .ContributionCard-headline {
    height: auto;
    display: flex;
    justify-content: center; }

.ContributionCard--book {
  background-color: #E8452C;
  justify-content: flex-start;
  cursor: default; }
  .ContributionCard--book:hover {
    background-color: #E8452C; }
  .ContributionCard--book .ContributionCard-headline {
    height: auto; }

.ContributionCard--wrapper {
  margin-left: 15px;
  margin-right: 15px; }

.ContributionCard-authors {
  display: block; }

.ContributionCard-author {
  display: flex;
  justify-content: center; }

.ContributionCard-authorName {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }

.ContributionCard-price {
  display: flex;
  justify-content: center;
  color: #e8452C;
  font-weight: bold; }

.ContributionCard-price {
  padding-top: 15px;
  display: flex;
  justify-content: center; }
  .ContributionCard-price .Text {
    color: #e8452C;
    font-weight: bold; }

.ContributionCard-spacing--xs {
  height: 5px; }

.ContributionCard-spacing--sm {
  height: 10px; }

.ContributionCard-spacing--md {
  height: 15px; }

.ContributionCard-background {
  border-radius: 0 0 24px 24px;
  padding: 10px 25px 12px 25px;
  margin: -25px; }
