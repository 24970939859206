.NotificationsPage-panel {
  border-radius: 24px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  height: calc(100% - 30px);
  transition: all 250ms ease; }

.NotificationsPage-spacing--xl {
  height: 170px; }

.NotificationsPage-spacing--lg {
  height: 64px; }

.NotificationsPage-spacing--md {
  height: 52px; }

.NotificationsPage-spacing--sm {
  height: 28px; }

.NotificationsPage-spacing--xs {
  height: 6px; }

@media (max-width: 767px) {
  .NotificationsPage-panel {
    padding: 10px 0; } }
