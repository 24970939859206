.PageCount {
  text-align: center;
  padding: 32px 40px 39px 40px; }
  .PageCount-spacing--xs {
    height: 10px; }
  .PageCount-spacing--sm {
    height: 22px; }
  .PageCount-spacing--md {
    height: 32px; }
  .PageCount-spacing--lg {
    height: 38px; }
  .PageCount-spacing--xl {
    height: 48px; }
  .PageCount-spacing--button {
    height: 16px; }
