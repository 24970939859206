.Headline, .Headline .headlineTextContainer > p {
  color: #3C3C3B;
  line-height: 1;
  font-family: "value-serif-bold", "Times New Roman", Times, serif;
  font-weight: normal;
  font-size: 40px; }

.Headline--hero {
  font-size: 72px;
  margin-bottom: 1.07px; }

.Headline--page {
  font-size: 56px;
  font-weight: bold;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--fundingPage {
  font-size: 48px;
  font-weight: bold;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--pageSubSmall {
  font-size: 18px;
  line-height: 20px;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--pageSub {
  font-size: 32px;
  font-weight: bold;
  line-height: 42px;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--subheadline {
  font-size: 24px;
  line-height: 30px;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--small {
  font-size: 20px;
  line-height: 24px;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--smallSemi {
  font-size: 28px;
  line-height: 24px;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--md {
  font-size: 32px;
  line-height: 42px;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--semi {
  font-size: 35px;
  line-height: 1;
  font-family: "value-serif-bold", "Times New Roman", Times, serif; }

.Headline--semiMd {
  font-size: 35px;
  line-height: 1;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--xs {
  font-size: 18px;
  line-height: 24px;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--xxs {
  font-size: 13px;
  line-height: 32px;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--card {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.Headline--center {
  text-align: center; }

.Headline--nowrap {
  white-space: normal; }

.Headline--bold {
  font-weight: bold; }

.Headline--primary {
  color: #E8452C; }

.Headline--secondary {
  color: #4B8795; }

.Headline--inverted {
  color: #fff; }

.Headline--light {
  color: #7E7E7B; }

.Headline--green {
  color: #328C69; }

.Headline--greenReward, .Headline--greenReward .headlineTextContainer > p {
  color: #38675B; }

.Headline--blue {
  color: #4B8795; }

.Headline--dark {
  color: #000000; }

.Headline--danger {
  color: #E7535F; }

.Headline--spacingXs {
  margin-bottom: 5px; }

.Headline--spacingSm {
  margin-bottom: 10px; }

.Headline--spacingSemi {
  margin-bottom: 15px; }

.Headline--spacingMd {
  margin-bottom: 32px; }

.Headline--uppercase {
  text-transform: uppercase; }

.Headline--price {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.Headline--smaller {
  font-size: 32px; }

@media (max-width: 767px) {
  .Headline--page {
    font-size: 40px; }
  .Headline--pageSub {
    font-size: 24px;
    line-height: 32px; }
  .Headline--pageSubSmall {
    font-size: 18px;
    line-height: 28px; }
  .Headline--hero {
    font-size: 50px;
    line-height: 1.2; }
  .Headline--heroMobile {
    font-size: 47px;
    line-height: 1; } }

@media (min-width: 1200px) {
  .Headline--nowrap {
    white-space: nowrap; } }
