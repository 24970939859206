.FundingNews-empty {
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid #38675B;
  padding: 15px 0;
  border-radius: 25px; }

.FundingNews-spacing--sm {
  height: 20px; }

.FundingNews-spacing--md {
  height: 24px; }
