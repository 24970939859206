.loader {
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  color: #E8452C;
  font-size: 200px; }
  .loader span {
    display: inline-block;
    animation: pulse .6s alternate infinite ease-in-out;
    margin-top: -200px; }
    .loader span:nth-child(odd) {
      animation-delay: .6s; }

@keyframes pulse {
  to {
    transform: scale(0.8);
    opacity: 0.5; } }
  .loader.\#faede5 {
    background: #faede5; }
