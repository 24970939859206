.RewardInfo {
  border-radius: 24px;
  border: 1px solid #38675B;
  padding: 25px;
  background-color: #fff;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  transition: all 250ms ease;
  justify-content: flex-start; }
  .RewardInfo-book {
    padding: 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%; }
  .RewardInfo-button {
    flex-shrink: 0; }
  .RewardInfo:hover {
    background-color: #f7f7f7; }
  .RewardInfo-headline {
    display: flex;
    justify-content: space-between; }
  .RewardInfo .RewardInfo-headline {
    height: auto;
    word-break: break-word; }
  .RewardInfo .settableError {
    margin-bottom: 1em;
    color: #8a6d3b;
    font-size: 0.8em; }

.RewardInfo--book {
  background-color: #E8452C;
  justify-content: flex-start;
  cursor: default; }
  .RewardInfo--book:hover {
    background-color: #E8452C; }
  .RewardInfo--book .RewardInfo-headline {
    height: auto; }

.RewardInfo-remaining {
  color: #7E7E7B; }

.RewardInfo-spacing--xs {
  height: 5px; }

.RewardInfo-spacing--sm {
  height: 15px; }

.RewardInfo-spacing--md {
  height: 50px; }

.RewardInfo-footerSection {
  border-top: 1px solid #38675B;
  margin-top: auto;
  padding-top: 25px; }

.RewardInfo-background {
  border-radius: 0 0 24px 24px;
  padding: 10px 25px 12px 25px;
  margin: -25px; }

.RewardInfo-background--financing {
  background-color: #328C69; }

@media (min-width: 1200px) {
  .RewardInfo {
    width: 310px; } }
