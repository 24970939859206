.ContributorHandshake {
  border-radius: 24px;
  width: 100%;
  box-shadow: 4px 4px 0 0 rgba(60, 60, 59, 0.16);
  background-color: #fff;
  padding: 20px 24px 32px;
  min-height: 110px;
  margin-top: 30px; }
  .ContributorHandshake-background {
    border-radius: 24px 24px 0 0;
    padding: 28px;
    margin: -28px -24px 0 -24px;
    background-color: #E8452C; }
