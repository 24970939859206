.GenreWrapper {
  margin: -4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }

.GenreWrapper-genre {
  margin: 4px; }

.GenreWrapper--center {
  justify-content: center; }

.GenreWrapper-genre--medium {
  margin: 8px 4px; }

.GenreWrapper--left {
  justify-content: flex-start; }
