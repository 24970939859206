.LoginPage {
  padding-top: 65px; }
  .LoginPage-headlineSpacing {
    height: 32px; }
  .LoginPage-formSpacing {
    height: 54px; }
  .LoginPage-buttonSpacing {
    height: 15px; }
  .LoginPage-linkSpacing {
    height: 10px; }
  .LoginPage-body {
    margin-top: 27px;
    margin-bottom: 64px; }
