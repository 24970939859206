.Notification {
  color: #000;
  text-decoration: unset;
  font-size: 15px;
  position: relative;
  list-style: none; }
  .Notification-content {
    max-height: 70vh;
    overflow: auto; }
  .Notification > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap; }
    .Notification > a:hover, .Notification > a:focus {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5; }
  .Notification a {
    text-decoration: none;
    padding: 0 29px 0 19px; }
    .Notification a:hover {
      background-color: #ececec; }
  .Notification-title {
    padding-bottom: 11px;
    margin: 0 29px 0 19px;
    border-bottom: 1px solid #ececec; }
  .Notification-body {
    padding: 14px 40px 20px 0;
    border-bottom: 1px solid #ececec; }
  .Notification-body--listing {
    border: 0;
    cursor: default; }
  .Notification-icon {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    flex-shrink: 0; }
  .Notification-text {
    white-space: normal;
    max-width: 100%; }
  .Notification-wrapper {
    display: inline-block; }
  .Notification-badge {
    padding: 0.35em 0.4em; }
  .Notification-wrapper-small .dropdown-menu {
    border-radius: 10px;
    left: auto;
    right: -10px;
    top: 75px;
    width: auto;
    padding: 10px 0 15px 0; }
    .Notification-wrapper-small .dropdown-menu:before {
      right: 36px; }
    .Notification-wrapper-small .dropdown-menu a {
      text-decoration: none;
      padding: 11px 20px; }
  .Notification-badge {
    padding-right: 4px; }
  .Notification-reject {
    margin-top: 13px;
    padding-left: 53px; }
  .Notification-date {
    position: absolute;
    right: 35px;
    bottom: 4px; }
  .Notification-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 22px; }

.Notification--new:after {
  content: "";
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #E8452C; }

.Notification--listing > a:hover, .Notification--listing > a:focus {
  background-color: white !important; }

@media (max-width: 767px) {
  .Notification a {
    padding: 0 5px; } }
