.FacebookButton {
  background-color: white;
  color: black;
  width: 229px;
  height: 32px;
  border-radius: 49px;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  font-size: 13px;
  line-height: 20px;
  display: flex;
  padding: 5px 17px;
  font-weight: bold;
  border: none;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  transition: all .25s; }
  .FacebookButton:hover {
    color: #4B8795; }
  .FacebookButton-icon {
    margin-right: 12px; }

.FacebookButton--login {
  background-color: #3B5998;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 24px;
  padding: 13px 20px;
  box-shadow: none;
  height: auto;
  font-size: 15px; }
  .FacebookButton--login:hover {
    color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); }
