.Screen {
  overflow: hidden;
  padding-bottom: 73px;
  margin-bottom: -73px; }

.Screen-content {
  max-width: 1140px;
  margin: auto;
  min-height: 60vh; }
  .Screen-content::before, .Screen-content::after {
    content: " ";
    display: table; }
  .Screen-content::after {
    clear: both; }

@media (min-width: 1200px) {
  .Screen-content {
    margin-top: 110px; } }
