.Info {
  position: absolute;
  z-index: 1000;
  max-width: 300px;
  background-color: black;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #ececec;
  margin-top: -15px; }
  .Info.in {
    opacity: .8; }
  .Info-icon {
    margin-top: -2px;
    max-width: 15px; }
  .Info .arrow {
    border: solid rgba(0, 0, 0, 0);
    height: 0;
    width: 0;
    pointer-events: none;
    border-top-color: black;
    border-width: 10px;
    margin-left: -10px;
    position: absolute;
    bottom: -20px; }
