.ForgottenPassword {
  padding-top: 65px; }
  .ForgottenPassword-headlineSpacing {
    height: 32px; }
  .ForgottenPassword-formSpacing {
    height: 54px; }
  .ForgottenPassword-buttonSpacing {
    height: 15px; }
  .ForgottenPassword-linkSpacing {
    height: 10px; }
  .ForgottenPassword-body {
    margin-top: 25px;
    margin-bottom: 64px; }
  .ForgottenPassword-success {
    background-color: #73E494;
    opacity: .9;
    border-radius: 140px;
    margin: auto;
    width: 90%;
    max-width: 730px;
    color: #3C3C3B;
    min-height: 64px;
    display: flex;
    align-items: center;
    margin-bottom: 44px;
    padding: 10px 33px;
    justify-content: space-between; }
