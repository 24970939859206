.AuthorCardMobile-container {
  padding-top: 0; }

.AuthorCardMobile-wrapper {
  color: #38675B;
  background-color: #ffffff;
  font-size: 16px;
  border-radius: 25px;
  padding: 15px 15px 20px 0;
  transition: all 250ms ease; }
  .AuthorCardMobile-wrapper:hover {
    box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16); }

.AuthorCardMobile-info {
  min-height: 310px;
  max-height: 310px; }

.AuthorCardMobile-photoSide {
  float: left;
  height: 255px;
  margin-top: -15px; }

.AuthorCardMobile-imgLeftSecondary {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 100%;
  width: 106%;
  object-fit: cover;
  padding-bottom: 15px; }

.AuthorCardMobile-containerGroup {
  padding-top: 0; }

.AuthorCardMobile-wrapperGroup {
  color: #38675B;
  background-color: #ffffff;
  font-size: 16px;
  border-radius: 25px;
  padding: 15px 15px 15px 0;
  margin: 20px 15px 20px 15px;
  min-height: 155px;
  max-height: 155px;
  transition: all 250ms ease; }
  .AuthorCardMobile-wrapperGroup:hover {
    box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16); }

.AuthorCardMobile-photoSideGroup {
  float: left;
  height: 155px;
  width: 100px;
  margin-top: -15px; }

.AuthorCardMobile-imgLeftGroup {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 100%;
  width: 100%;
  object-fit: cover; }

.AuthorCardMobile-moreButton {
  padding-left: 10px; }

.AuthorCardMobile-moreButtonGroup {
  padding-left: 10px; }

@media (max-width: 360px) {
  .AuthorCardMobile-info {
    width: 55%;
    padding-left: 10px; }
  .AuthorCardMobile-infoGroup {
    width: 55%;
    padding-left: 10px; } }

@media (min-width: 1200px) {
  .AuthorCardMobile-container {
    padding-top: 15px; }
  .AuthorCardMobile-wrapper {
    color: #38675B;
    background-color: #ffffff;
    font-size: 16px;
    border-radius: 25px;
    padding: 15px 15px 15px 0;
    width: 100%;
    float: left;
    margin: 20px 15px 20px 0;
    min-height: 255px;
    max-height: 255px; }
  .AuthorCardMobile-info {
    width: 44%;
    margin-left: 50%;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    padding-left: 0; }
  .AuthorCardMobile-photoSide {
    height: 255px;
    width: 450px;
    margin-top: -15px;
    float: left; }
  .AuthorCardMobile-imgLeft {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .AuthorCardMobile-moreButton {
    padding-left: 0; }
  .AuthorCardMobile-containerGroup {
    display: flex;
    justify-content: space-between;
    margin-top: -25px; }
  .AuthorCardMobile-wrapperGroup {
    color: #38675B;
    background-color: #ffffff;
    font-size: 16px;
    border-radius: 25px;
    padding: 15px 15px 15px 0;
    width: 49.3%;
    margin: 0 0 20px 0;
    min-height: 255px;
    max-height: 255px;
    float: left; }
    .AuthorCardMobile-wrapperGroup:last-child {
      float: right; }
  .AuthorCardMobile-infoGroup {
    width: 65%;
    margin-left: 35%;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    padding-left: 15px; }
  .AuthorCardMobile-photoSideGroup {
    height: 255px;
    width: 175px;
    margin-top: -15px;
    float: left; }
  .AuthorCardMobile-imgLeftGroup {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .AuthorCardMobile-moreButtonGroup {
    padding-left: 15px; } }

@media not all and (min-resolution: 0.001dpcm) {
  .AuthorCardMobile-info {
    padding-left: 35px; }
  .AuthorCardMobile-infoGroup {
    padding-left: 25px; } }
