.NewsletterThanks {
  padding: 50px 20px 0 50px;
  display: flex; }
  .NewsletterThanks-spacing--xs {
    height: 15px; }
  .NewsletterThanks-spacing--sm {
    height: 25px; }
  .NewsletterThanks-imageBox {
    background-image: url("./img/bg.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center; }
  .NewsletterThanks-left {
    width: 45%;
    padding-top: 70px;
    padding-right: 20px; }
  .NewsletterThanks-right {
    width: 55%; }

@media (max-width: 767px) {
  .NewsletterThanks {
    flex-direction: column;
    text-align: center;
    padding: 25px 25px 0; }
    .NewsletterThanks-left {
      padding-bottom: 25px;
      width: 100%; }
    .NewsletterThanks-right {
      width: 100%; } }
