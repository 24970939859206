.References-list {
  width: 100%;
  padding: 0;
  margin: 0;
  display: block;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 4px 4px 0 0 rgba(60, 60, 59, 0.16); }

.References-item {
  display: block;
  padding: 25px;
  border-bottom: 1px solid rgba(60, 60, 59, 0.16);
  color: #3C3C3B;
  font-size: 18px;
  line-height: 30px; }
  .References-item a {
    color: inherit;
    text-decoration: underline; }
    .References-item a:hover {
      text-transform: none; }
  .References-item:last-child {
    border-bottom: 0; }

.todos-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px; }
