.HandshakeModal {
  background-color: #FAEDE5;
  padding: 50px 30px; }
  .HandshakeModal-title {
    text-align: center;
    margin-bottom: 62px; }
  .HandshakeModal-hand {
    padding-top: 32px; }
  .HandshakeModal-description {
    padding-left: 70px;
    text-align: center; }
  .HandshakeModal-description--left {
    padding-right: 70px;
    padding-left: 0; }
  .HandshakeModal-desc {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/images/svg/ovalPrimary.svg");
    padding: 60px 60px 130px;
    background-size: contain;
    background-repeat: no-repeat; }
  .HandshakeModal-button {
    margin-top: 64px; }
  .HandshakeModal-author {
    max-width: 165px;
    min-width: 110px; }
  .HandshakeModal-icons {
    margin: 0 7px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-self: flex-start;
    padding-top: 30px; }
  .HandshakeModal-icons--pending {
    padding-top: 0; }
  .HandshakeModal-link {
    text-decoration: none;
    color: #7E7E7B; }
    .HandshakeModal-link:hover {
      color: #7E7E7B; }
  .HandshakeModal-spacing--xxs {
    height: 2px; }
  .HandshakeModal-spacing--md {
    height: 11px; }
  .HandshakeModal-spacing--sm {
    height: 5px; }
  .HandshakeModal-spacing--lg {
    height: 16px; }
  .HandshakeModal-spacing--xl {
    height: 35px; }
  .HandshakeModal .has-feedback label ~ .form-control-feedback {
    top: auto; }
  .HandshakeModal .contract-muster {
    border: 1px solid #c9c9c9;
    padding: 10px;
    overflow: scroll;
    height: 400px;
    margin-top: 160px;
    font-size: 12px;
    font-family: Arial; }
    .HandshakeModal .contract-muster table, .HandshakeModal .contract-muster td, .HandshakeModal .contract-muster p {
      font-size: 12px; }
    .HandshakeModal .contract-muster h1, .HandshakeModal .contract-muster h2 {
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 5px; }
    .HandshakeModal .contract-muster td {
      padding: 5px 5px;
      vertical-align: top; }

@media (max-width: 767px) {
  .HandshakeModal {
    padding: 30px 0; }
    .HandshakeModal-description {
      padding: 0; }
    .HandshakeModal-hands {
      width: 50px; }
    .HandshakeModal-pending {
      width: 80px; }
    .HandshakeModal-pending--clock {
      width: 35px;
      margin: auto; }
    .HandshakeModal-desc {
      padding: 20px 0 100px; }
    .HandshakeModal-hand {
      width: 32px; } }
