a.ProjectCard-link, a.ProjectCard-link:hover {
  text-decoration: none; }

.ProjectCard {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 30px;
  margin-left: 20px;
  height: calc(100% - 30px);
  transition: all 250ms ease;
  justify-content: flex-start;
  height: 415px;
  width: 295px;
  background: white; }
  .ProjectCard:hover {
    box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16); }
  @media (min-width: 1200px) {
    .ProjectCard {
      margin-left: 0; } }
  .ProjectCard-bibliography {
    display: flex;
    justify-content: center;
    padding: 5px;
    margin-bottom: -44px; }
  .ProjectCard-coverPhoto {
    max-width: 100%;
    width: auto;
    height: auto;
    max-width: 210px;
    display: flex;
    align-self: flex-start;
    margin-top: -5%; }
  .ProjectCard-book {
    padding: 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%; }
  .ProjectCard-button {
    flex-shrink: 0; }
  .ProjectCard:hover {
    background-color: #f7f7f7; }
  .ProjectCard-headline {
    display: flex;
    justify-content: center;
    text-align: center; }
  .ProjectCard-progress {
    padding: 15px;
    margin-top: auto; }
  .ProjectCard-progress--emptyNews {
    margin-top: auto; }

.ProjectCard--eshop {
  background-image: url("/images/eshop.png");
  background-position: center bottom 25px;
  background-repeat: no-repeat;
  background-color: #D1C8C2;
  min-height: 440px;
  position: relative;
  justify-content: flex-start;
  cursor: default; }
  .ProjectCard--eshop:hover {
    background-color: #D1C8C2;
    cursor: pointer; }
  .ProjectCard--eshop:after {
    content: "";
    position: absolute;
    width: calc(100% + 20px);
    height: 233px;
    background-image: url("/images/svg/buybook.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    top: 50%;
    left: -10px;
    transform: translateY(-50%); }
  .ProjectCard--eshop .ProjectCard-headline {
    height: auto;
    display: flex;
    justify-content: center; }

.ProjectCard--book {
  background-color: #E8452C;
  justify-content: flex-start;
  cursor: default; }
  .ProjectCard--book:hover {
    background-color: #E8452C; }
  .ProjectCard--book .ProjectCard-headline {
    height: auto; }

.ProjectCard--wrapper {
  margin-left: 15px;
  margin-right: 15px; }

.ProjectCard-authors {
  display: block; }

.ProjectCard-author {
  display: flex;
  justify-content: center; }

.ProjectCard-authorName {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }

.ProjectCard-price {
  display: flex;
  justify-content: center;
  color: #e8452C;
  font-weight: bold; }

.ProjectCard-price {
  padding-top: 15px;
  display: flex;
  justify-content: center; }
  .ProjectCard-price .Text {
    color: #e8452C;
    font-weight: bold; }

.ProjectCard-collected {
  color: #4B8795; }

.ProjectCard-remaining {
  color: #7E7E7B; }

.ProjectCard-spacing--xs {
  height: 5px; }

.ProjectCard-spacing--sm {
  height: 10px; }

.ProjectCard-spacing--md {
  height: 15px; }

.ProjectCard-background {
  border-radius: 0 0 24px 24px;
  padding: 10px 25px 12px 25px;
  margin: -25px; }

@media (max-width: 414px) {
  .ProjectCard--eshop {
    min-height: 465px; } }
