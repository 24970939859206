.InputField input[type='checkbox'] {
  display: none; }

.InputField-checkbox {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 0;
  background-color: white;
  border: 2px solid #ececec;
  transition: all 125ms ease; }

.InputField-currency {
  position: relative;
  left: -30px;
  top: 8px;
  z-index: 10;
  width: 0; }

.InputField input[type='checkbox']:checked + .InputField-checkbox {
  background-color: #E8452C;
  border-color: #cb2e16;
  background-image: url("/images/svg/check-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.InputField .checkbox.disabled label {
  color: rgba(117, 124, 149, 0.5); }

.InputField .checkbox.disabled .InputField-checkbox {
  background-color: #F4F4F4; }

.form-group {
  margin: 5px 0; }
  .form-group .input__password_toggler {
    position: absolute;
    right: 0;
    top: 22px; }
  .form-group label {
    color: #757C95;
    font-weight: normal;
    font-size: 1.4rem;
    width: 100%;
    display: block; }
  .form-group textarea {
    width: 100% !important; }
  .form-group input {
    border: solid 2px rgba(60, 60, 59, 0.16);
    border-radius: 5px !important;
    padding-left: 10px;
    font-size: 1.7rem;
    color: #000;
    outline: none;
    text-align: left;
    box-shadow: none; }
    .form-group input.form-control {
      height: 38px; }
    .form-group input::placeholder {
      color: #7E7E7B; }
    .form-group input:-ms-input-placeholder {
      color: #7E7E7B; }
    .form-group input::-ms-input-placeholder {
      color: #7E7E7B; }
  .form-group .input-group .has-append {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0; }
  .form-group .input-group .input-group-append, .form-group .input-group .input-group-text {
    background-color: transparent;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }
  .form-group .input-group .input-group-text {
    border: none;
    border-top: 2px solid rgba(60, 60, 59, 0.16);
    border-right: 2px solid rgba(60, 60, 59, 0.16);
    border-bottom: 2px solid rgba(60, 60, 59, 0.16); }

.checkbox label {
  padding-left: 24px; }
