.Bubble {
  padding: 40px 0;
  background-color: white;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  border-radius: 24px;
  margin-bottom: 25px; }
  .Bubble-detail {
    display: flex; }
  .Bubble-detail--locked {
    filter: grayscale(100%);
    opacity: 0.6; }
  .Bubble-icon {
    border-right: 1px solid #ececec;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-direction: column;
    padding: 0 15px;
    text-align: center; }
  .Bubble-body {
    padding: 0 40px;
    width: 100%; }
  .Bubble-description {
    padding-bottom: 24px; }
  .Bubble-price {
    padding-left: 30px;
    padding-right: 30px;
    border-left: 1px solid #ececec;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0; }
  .Bubble-contributor {
    padding-top: 24px;
    border-top: 1px solid #ececec; }
  .Bubble-spacing--wSm {
    width: 5px; }
  .Bubble-spacing--wMd {
    width: 16px; }
  .Bubble-spacing--xs {
    height: 5px; }
  .Bubble-spacing--sm {
    height: 8px; }
  .Bubble-spacing--md {
    height: 15px; }
  .Bubble-spacing--lg {
    height: 24px; }

.Bubble--done .Bubble-body {
  position: relative; }
  .Bubble--done .Bubble-body:after {
    content: "";
    position: absolute;
    top: 15px;
    right: 40px;
    background-image: url("/images/svg/arrow_down--dark.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 16px;
    height: 8px; }

.Bubble--small {
  padding: 20px 30px;
  position: relative; }
  .Bubble--small .Bubble-detail {
    flex-wrap: nowrap; }
  .Bubble--small .Bubble-body {
    padding: 0 25px;
    display: flex;
    align-items: center;
    position: relative; }
    .Bubble--small .Bubble-body:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      background-image: url("/images/svg/arrow_down--dark.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 16px;
      height: 8px; }
  .Bubble--small .Bubble-contributor {
    display: none; }
  .Bubble--small .Bubble-description {
    padding-bottom: 0;
    border: 0;
    position: relative;
    margin-top: 0; }
  .Bubble--small .Bubble-icon {
    border: 0;
    width: auto;
    max-width: 32px; }

.Handshake {
  margin-bottom: 20px; }
  .Handshake-btn {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 250ms ease;
    background-color: #EEEEEE;
    color: black;
    border-radius: 6px;
    padding: 8px 18px;
    display: flex;
    align-items: center;
    height: 36px; }
    .Handshake-btn:hover {
      color: #E8452C;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); }
  .Handshake-btn--isSuggested {
    background-color: #E8452C;
    color: white;
    padding: 2px 18px; }
    .Handshake-btn--isSuggested:hover {
      color: white; }
  .Handshake-trash {
    cursor: pointer;
    transition: all 250ms ease;
    background-color: #EEEEEE;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    border-radius: 6px; }
    .Handshake-trash:hover {
      color: #E8452C;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); }

@media (max-width: 767px) {
  .Bubble {
    padding-left: 30px;
    padding-right: 30px; }
    .Bubble-detail {
      flex-wrap: wrap;
      justify-content: center; }
    .Bubble-body {
      padding: 0; }
    .Bubble-icon {
      border: none; }
    .Bubble-description {
      text-align: center;
      margin-top: 13px;
      padding-bottom: 10px; }
    .Bubble-contributor {
      border: none;
      padding-top: 0; }
    .Bubble-price {
      border-left: none;
      border-top: 1px solid #ececec;
      padding-top: 25px;
      margin-top: 25px;
      text-align: center;
      padding-left: 0;
      width: 100%; }
  .Bubble--done {
    padding-left: 30px;
    padding-right: 30px; }
    .Bubble--done .Bubble-icon {
      max-width: 56px; }
    .Bubble--done .Bubble-body:after {
      display: none; }
    .Bubble--done .Bubble-contributor {
      padding-top: 24px;
      border-top: 1px solid #ececec; }
  .Bubble--small .Bubble-body:after {
    display: block; } }
