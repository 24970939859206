.WelcomeBack-card {
  background: transparent url("./img/oval.svg");
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 264px;
  width: 264px;
  padding: 30px;
  text-align: center; }
  .WelcomeBack-card a {
    color: white;
    text-decoration: none; }

.WelcomeBack-subrole {
  position: relative; }
  .WelcomeBack-subrole:after {
    content: "";
    position: absolute;
    right: -15px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    top: 50%;
    background-color: #3C3C3B; }

.WelcomeBack-subroleWrapper:last-child .WelcomeBack-subrole:after {
  display: none; }

.WelcomeBack-spacing--lg {
  height: 25px; }

.WelcomeBack-spacing--md {
  height: 15px; }

.WelcomeBack-spacing--sm {
  height: 10px; }

@media (max-width: 767px) {
  .WelcomeBack-card {
    margin-top: 40px; } }
