.Footer {
  padding-top: 16px;
  padding-bottom: 32px;
  text-align: center;
  background-color: #F3E6DE;
  border-top: 1px solid #DBD0C9; }
  .Footer-links {
    display: flex;
    margin: auto;
    margin-top: 20px;
    max-width: 700px;
    justify-content: center; }
  .Footer-link {
    padding: 0 20px; }
  .Footer-contact {
    padding: 0 20px; }

.Footer--transparent {
  background-color: transparent;
  border-top: none;
  margin-top: 50px; }

.Footer-email {
  font-size: 16px;
  color: #E8452C;
  margin-top: 10px; }

.Footer-feeds {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px; }

.Footer-feed {
  margin: 0 20px;
  color: #E8452C;
  font-size: 16px;
  text-decoration: none; }
  .Footer-feed:hover, .Footer-feed:focus {
    color: #E8452C; }

.Footer-feed--blog .Footer-feedIcon {
  margin-right: 8px; }

.Footer-feed--facebook .Footer-feedIcon {
  margin-right: 16px; }

.Footer-feed--instagram .Footer-feedIcon {
  margin-right: 6px; }

@media (max-width: 767px) {
  .Footer-links {
    flex-direction: column; }
  .Footer-link {
    margin-bottom: 4px; }
  .Footer-feeds {
    flex-wrap: wrap; }
  .Footer-feed {
    margin-bottom: 15px; } }

.Footer-pg-banner {
  margin-top: 32px;
  text-align: center; }
  .Footer-pg-banner img {
    width: 300px; }
