.Text {
  font-size: 18px;
  color: #3C3C3B; }

.Text--disabled {
  color: #7E7E7B; }

.Text--underline {
  text-decoration: underline; }

.Text--center {
  text-align: center; }

.Text--left {
  text-align: left; }

.Text--longName {
  padding-top: 15px;
  line-height: 1em; }

.Text--bold {
  font-weight: bold; }

.Text--normal {
  font-weight: 400; }

.Text--lighter {
  font-weight: lighter; }

.Text--primary {
  color: #E8452C; }

.Text--secondary {
  color: #4B8795; }

.Text--light {
  color: #7E7E7B; }

.Text--green {
  color: #328C69; }

.Text--greenReward {
  color: #38675B; }

.Text--capitalized {
  text-transform: capitalize; }

.Text--inverted {
  color: #fff; }

.Text--dark {
  color: #000; }

.Text--small {
  font-size: 16px; }

.Text--medium {
  font-size: 14px; }

.Text--semi {
  font-size: 18px; }

.Text--noWrap {
  white-space: nowrap; }

.Text--cartPrice {
  font-size: 20px; }

.Text--xs {
  font-size: 13px; }

.Text--xxs {
  font-size: 12px; }

.Text--lg {
  font-size: 24px;
  line-height: 30px; }

.Text--cartItem {
  font-size: 24px; }

.Text--xl {
  font-size: 32px;
  line-height: 42px; }

.Text--midSemi {
  font-size: 22px; }

.Text--xxl {
  font-size: 40px;
  line-height: 48px; }

.Text--statistics {
  font-size: 72px;
  font-family: "value-serif-bold", "Times New Roman", Times, serif; }

.Text--mAuto {
  margin: auto; }

.Text--label {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px; }

.Text--uppercase {
  text-transform: uppercase; }

.Text--lowercase {
  text-transform: lowercase; }
  .Text--lowercase:first-letter {
    text-transform: uppercase; }

.Text--lineHeight {
  line-height: 30px; }

.Text--lineHeightSmall {
  line-height: 1.1; }

.Text--textBreak {
  word-break: break-all; }

.Text--pointer {
  cursor: pointer; }

.Text--hover {
  transition: all 250ms ease; }
  .Text--hover:hover {
    color: #E8452C; }

.Text--float {
  float: left; }

.Text--floatRight {
  float: right; }

.Text--firstUp {
  display: inline-block;
  margin-right: 4px; }
  .Text--firstUp:first-letter {
    text-transform: uppercase; }

@media (max-width: 767px) {
  .Text--lg {
    font-size: 18px;
    line-height: 24px; } }
