.Form-body--center {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }

.has-feedback label ~ .form-control-feedback {
  top: 30px;
  right: 22px; }

.form-group.has-error .input-group .input-group-text {
  border-color: #a94442; }

.form-group.has-warning .input-group .input-group-text {
  border-color: #8a6d3b; }
