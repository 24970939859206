.UploadFile-button {
  margin-top: 42px; }

.UploadFile-desc {
  padding-top: 15px;
  text-align: center;
  width: 110px;
  text-decoration: none; }

.UploadFile-placeholder {
  position: absolute;
  width: 195px;
  height: 195px;
  top: 5%;
  border: 1px dashed #DBD0C9;
  background-image: url("/images/svg/plus.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 24px;
  transition: all 125ms ease;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16); }
  .UploadFile-placeholder:hover {
    background-color: #f7f7f7; }

.UploadFile-plusWrapper {
  width: 225px;
  padding-bottom: 225px;
  position: relative;
  display: inline-block;
  cursor: pointer; }

.UploadFile-plus {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 1px dashed #DBD0C9;
  background-image: url("/images/svg/plus.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 24px;
  transition: all 125ms ease;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16); }
  .UploadFile-plus:hover {
    background-color: #f7f7f7; }

.UploadFile-files {
  flex-flow: wrap;
  justify-content: center;
  margin: -15px;
  position: relative; }

.UploadFile-files--editable {
  display: inline-flex;
  float: left;
  min-height: 225px;
  cursor: pointer;
  margin: 0;
  justify-content: flex-start; }

.UploadFile-files--center {
  justify-content: center; }
  .UploadFile-files--center .UploadFile-placeholder {
    transform: translateX(-50%); }

.UploadFile-files--border {
  align-items: center;
  width: 306px;
  min-height: 308px;
  margin: auto; }
  .UploadFile-files--border:before {
    content: "";
    background-color: white;
    position: absolute;
    top: -15px;
    bottom: -15px;
    left: -15px;
    right: -15px;
    z-index: -1; }

.UploadFile-file {
  padding: 0 10px;
  margin-top: 12px;
  width: 215px;
  height: 195px;
  position: relative; }

.UploadFile-file--big {
  width: 250px;
  padding-bottom: 250px;
  position: relative; }

.UploadFile-fileImage {
  background: transparent no-repeat 50% 50%;
  background-size: cover;
  box-shadow: 4px 4px 0 0 rgba(60, 60, 59, 0.16);
  border-radius: 24px;
  height: 100%; }

.UploadFile-fileImage--sample {
  background-color: white;
  background-position: top 15px left 10px;
  background-size: 50px; }

.UploadFile-trash {
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 1;
  color: white;
  background-color: #E8452C;
  font-size: 16px;
  padding: 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: text-shadow 125ms ease;
  text-shadow: 0 0 4px #3C3C3B; }
  .UploadFile-trash:hover {
    text-shadow: 0 0 8px #3C3C3B; }

.UploadFile-name {
  display: none; }

.UploadFile--small .UploadFile-files {
  margin: -7.5px; }

.UploadFile--small .UploadFile-fileImage {
  top: 7.5px;
  left: 7.5px;
  right: 7.5px;
  bottom: 7.5px; }

.UploadFile--count3 .UploadFile-file {
  height: auto;
  width: 33.33333%;
  padding-bottom: 33.33333%; }

.UploadFile .files-list {
  width: 100%;
  padding: 0; }
  .UploadFile .files-list li {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
    color: #000;
    border-bottom: 1px solid #000;
    padding: 5px 0; }

.UploadFile .dropzone {
  border-width: 2px;
  border-color: #B6B6B6;
  border-style: dashed;
  border-radius: 5px;
  background-color: #FBFBFB;
  color: #686868;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }
  .UploadFile .dropzone img {
    max-height: 200px;
    margin: 5px; }

.UploadFile.has-content .dropzone {
  border: none; }

.UploadFile--profile .UploadFile-files {
  justify-content: flex-start; }

.UploadFile--count3 .UploadFile-files {
  justify-content: flex-start;
  margin: 0; }

.UploadFile--count3 .UploadFile-fileImage {
  box-shadow: none;
  border-radius: 4px; }

.UploadFile--preview .UploadFile-files {
  justify-content: flex-start; }

.UploadFile--preview .UploadFile-fileImage {
  box-shadow: none;
  border-radius: 4px; }

.UploadFile--preview .UploadFile-file {
  width: 90px;
  height: 90px; }

.UploadFile-files--profilePic {
  background-image: url("/images/svg/profilepic.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 329px; }
  .UploadFile-files--profilePic .UploadFile-file {
    width: 75%; }

.UploadFile-files--samples {
  justify-content: flex-start; }

.UploadFile .slick-slide {
  padding-right: 24px; }

.UploadFile-file--samples {
  width: 100%;
  height: 200px;
  margin: 0; }
  .UploadFile-file--samples > a {
    text-decoration: none; }
  .UploadFile-file--samples .UploadFile-fileImage {
    border-radius: 8px;
    height: 140px;
    width: 110px; }

.UploadFile--gallery .UploadFile-file {
  padding-bottom: 0;
  display: flex;
  justify-content: space-around; }

.UploadFile--gallery .UploadFile-fileImage {
  border-radius: 4px;
  height: 57px;
  width: 75px; }

.UploadFile-file--profilePic {
  min-width: 170px;
  min-height: 170px; }

.UploadFile--center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.UploadFile .slick-track {
  margin: 0; }

.UploadFile-files--samples-grid {
  width: 100%;
  padding: 30px 15px 0 30px;
  display: flex;
  justify-content: flex-start; }

@media (max-width: 767px) {
  .UploadFile-plusWrapper {
    width: 140px;
    height: 140px;
    padding-bottom: 0; }
  .UploadFile-placeholder {
    width: 110px;
    height: 110px; }
  .UploadFile-files--editable {
    min-height: 165px; }
  .UploadFile--preview .UploadFile-file {
    width: 140px;
    height: 140px;
    padding-bottom: 0; }
  .UploadFile--gallery .UploadFile-fileImage {
    height: 45px;
    width: 60px; } }

@media (max-width: 414px) {
  .UploadFile--gallery .UploadFile-fileImage {
    height: 57px;
    width: 75px; } }

@media (min-width: 1200px) {
  .UploadFile-files {
    display: flex; } }
