.BaseInfo-number {
  color: white;
  font-weight: bold;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 15px;
  z-index: 10; }

.BaseInfo-middle {
  color: white;
  font-weight: bold;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 15px;
  z-index: 10; }
  .BaseInfo-middle:after {
    content: "";
    position: absolute;
    width: 146%;
    border: 2px dashed #EB3F2E;
    z-index: -2; }
    @media not all and (min-resolution: 0.001dpcm) {
      .BaseInfo-middle:after {
        content: "";
        position: absolute;
        width: 150%;
        border: 2px dashed #EB3F2E;
        z-index: -2;
        top: 13px;
        left: -150px; } }

.BaseInfo-middleMobile {
  color: white;
  font-weight: bold;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 15px;
  z-index: 10; }
  .BaseInfo-middleMobile:after {
    content: "";
    position: absolute;
    height: 200%;
    border: 2px dashed #EB3F2E;
    z-index: -2;
    bottom: 0;
    left: 29px; }
