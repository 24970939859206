.FollowedProjects-select {
  width: 244px; }

.FollowedProjects-select--big {
  width: auto;
  max-width: 500px;
  min-width: 244px; }

.FollowedProjects-spacing--xxl {
  height: 138px; }

.FollowedProjects-spacing--xl {
  height: 80px; }

.FollowedProjects-spacing--lg {
  height: 25px; }

.FollowedProjects-spacing--md {
  height: 15px; }

.FollowedProjects-spacing--sm {
  height: 10px; }

.FollowedProjects-spacing--xs {
  height: 8px; }

@media (max-width: 767px) {
  .FollowedProjects-select {
    width: auto;
    max-width: 100%; } }
