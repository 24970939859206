.modal-body {
  padding: 0; }

.modal-dialog {
  max-width: 820px; }

.modal-header {
  position: absolute;
  z-index: 9;
  height: 64px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  border-bottom: none; }
  .modal-header .close-btn {
    height: 24px;
    padding: 0 10px 0 0;
    cursor: pointer; }

.modal-content {
  border-radius: 24px; }
