@import "../../stylesheets/variables/colors.sass";
@import "../../stylesheets/variables/shadows.sass";
@import "../../stylesheets/variables/breakpoints.sass";

.Tabs {
  margin-left: -1500px;
  margin-right: -1500px;
  margin-bottom: -120px;
  padding: 0 1500px 120px;
  background-color: $bgColor-light;
  margin-top: 115px;
  box-shadow: 4px -4px 0 #dcd1c9;

  &-headerContainer {
    position: relative;
    top: -55px;
    display: flex;
    align-items: flex-end; }

  &-header {
    min-width: 260px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 24px 24px 0 0;
    background-color: white;
    box-shadow: 4px 0 0 #dcd1c9;
    padding: 13px 50px;
    margin: 0 10px;
    font-size: 16px;
    font-weight: bold; }

  &-header--active {
    color: $brandColor-primary;
    background-color: $bgColor-light;
    font-size: 18px;
    height: 55px;
    padding: 15px 50px; } }

@media (max-width: $screen-sm-max) {
  .Tabs {
    &-header {
      font-size: 14px;
      min-width: 80px;
      padding-left: 30px;
      padding-right: 30px; }

    &-header--active {
      font-size: 16px; } } }

@media (max-width: $screen-sm-max) {
  .Tabs {
    &-headerContainer {
      margin-left: -15px;
      margin-right: -15px; }
    &-header {
      font-size: 14px;
      min-width: 80px;
      padding-left: 15px;
      padding-right: 15px;
      flex-grow: 1; }

    &-header--active {
      font-size: 16px; } } }

@media (max-width: $screen-xs-max) {
  .Tabs {
    &-header {
      font-size: 13px;
      min-width: 60px;
      margin: 0 3px;
      padding-left: 10px;
      padding-right: 10px; }

    &-header--active {
      font-size: 14px; } } }

@media (max-width: 330px) {
  .Tabs {
    &-header {
      font-size: 11px;
      min-width: 70px;
      margin: 0 2px; }

    &-header--active {
      font-size: 14px; } } }

@media (min-width: $screen-lg-min) {
  .Tabs {
    margin-bottom: 0; } }

