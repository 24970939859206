.NotAllowedPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px; }
  .NotAllowedPage-icon {
    margin-bottom: 31px; }
  .NotAllowedPage-title {
    margin-bottom: 57px; }
