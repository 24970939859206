.ConfirmBox-main {
  border-radius: 24px;
  width: 100%;
  background: #73E494 url(./img/bg.svg);
  box-shadow: 0 4px 0 0 rgba(60, 60, 59, 0.16);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 80px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  padding: 30px 45px;
  margin-bottom: 25px; }

@media (max-width: 767px) {
  .ConfirmBox-main {
    padding: 24px; } }
