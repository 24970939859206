@import "../../../stylesheets/variables/colors.sass";
@import "../../../stylesheets/variables/shadows.sass";
@import "../../../stylesheets/variables/breakpoints.sass";

.FundingTabs {
  margin-left: -1500px;
  margin-right: -1500px;
  padding: 0 1500px;
  background-color: #ffff;
  margin-top: 30px;

  &-headerContainer {
    position: relative;
    top: -55px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; }

  &-header {
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    color: #38675B;
    padding: 13px 20px 16px 20px;
    font-size: 16px;
    font-weight: bold;
    flex: 0 0 auto; }

  &-header--active {
    color: #38675B;
    border-bottom: 3.5px solid #38675B; }

  &-donateButton {
    z-index: 1;
    padding: 10px 0 0 80px; }

  &-shareButton {
    z-index: 1;
    padding: 10px 0 0 240px; }

  &-onlyShareButton {
    z-index: 1;
    padding: 10px 0 0 470px; }

  &-body {
    max-width: 620px;
    padding-left: 10px; } }

@media (max-width: $screen-sm-max) {
  .FundingTabs {
    &-header {
      font-size: 14px;
      min-width: 80px;
      padding-left: 30px;
      padding-right: 30px; }

    &-header--active {
      font-size: 16px; } } }

@media (max-width: $screen-sm-max) {
  .FundingTabs {

    &-header {
      font-size: 14px;
      min-width: 80px;
      padding-left: 15px;
      padding-right: 15px;
      flex-grow: 1; }

    &-header--active {
      font-size: 16px; } } }

@media (max-width: $screen-xs-max) {
  .FundingTabs {
    &-header {
      font-size: 16px;
      margin: 0 3px;
      padding-left: 10px;
      padding-right: 10px; }

    &-header--active {
      font-size: 16px; }

    &-headerContainer {} } }

@media (max-width: 330px) {
  .FundingTabs {
    &-header {
      font-size: 13px;
      min-width: 70px;
      margin: 0 2px; }

    &-header--active {
      font-size: 13px; } } }

@media (min-width: $screen-lg-min) {
    .FundingTabs {
        &-headerContainer {
            min-width: 580px; } } }
