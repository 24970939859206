.Genre {
  margin: 0;
  padding: 8px 16px;
  line-height: 1;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #fff;
  color: #4B8795;
  box-shadow: 2px 2px 0 0 rgba(60, 60, 59, 0.16);
  transition: all 125ms ease; }

.Genre--clickable {
  cursor: pointer; }

.Genre--small {
  padding: 0 16px;
  text-transform: none; }

.Genre--medium {
  padding: 8px 16px;
  text-transform: lowercase;
  font-size: 14px; }
  .Genre--medium:first-letter {
    text-transform: uppercase; }

.Genre.isActive {
  background-color: #4B8795;
  color: #fff; }

.Genre.isDisabled {
  background-color: #bdbdbd;
  color: #fff; }

@media (max-width: 767px) {
  .Genre {
    padding: 8px 15px; }
  .Genre--medium {
    padding: 8px 15px;
    text-transform: lowercase;
    font-size: 14px; } }
