.MainNavigation {
  display: flex;
  padding: 14px 40px;
  align-items: center;
  margin-bottom: 37px;
  background-color: #FAEDE5;
  justify-content: space-between;
  min-height: 57px;
  height: 57px;
  position: relative;
  z-index: 99; }
  .MainNavigation-cart {
    position: relative;
    display: flex;
    align-items: center; }
  .MainNavigation-number {
    min-width: 18px;
    height: 18px;
    position: absolute;
    background-color: #38675B;
    border-radius: 9px;
    padding: 0 3px;
    border: 2px solid white;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    top: calc(50% - 14px);
    right: -8px; }
  .MainNavigation-burger {
    position: relative;
    left: -20px;
    top: -28px; }
  .MainNavigation-burgerItem {
    background-color: #7E7E7B;
    width: 24px;
    height: 2px;
    border-radius: 4px;
    margin: 5px 0; }
  .MainNavigation-alert {
    background: #fff3cd;
    opacity: 0.9;
    font-size: 16px;
    line-height: 1.2;
    color: #E8452C;
    padding: 20px 0;
    box-shadow: inset 0 -3px 0 #ffeeba; }

.MainNavigation-content {
  display: flex;
  flex-grow: 1; }

.MainNavigation-links {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: 0; }

.MainNavigation-buttons {
  display: flex;
  margin: 0;
  padding: 0; }

.MainNavigation-rightContent {
  display: flex;
  padding: 0;
  margin: 0; }

@media (max-width: 360px) {
  .MainNavigation-rightContent {
    white-space: nowrap;
    margin-left: -5px; } }

.MainNavigation-link {
  display: flex;
  align-items: center;
  padding-left: 20px; }

.MainNavigation-linkCart {
  display: flex;
  align-items: center; }

.MainNavigation-cartCount {
  font-size: 60%;
  padding: .6em;
  margin: 0 5px;
  border-radius: 999px;
  line-height: .75em;
  color: #328c69;
  text-align: center;
  min-width: 2em;
  font-weight: bold;
  background: #FAEDE5;
  border: 1px solid; }

.MainNavigation-link--right {
  padding-right: 15px; }

.MainNavigation-button {
  display: block; }

.MainNavigation--landing {
  padding: 25px 40px;
  margin-bottom: 37px;
  box-shadow: none;
  background-color: transparent; }

.bm-menu {
  background-color: white;
  padding: 40px; }

.bm-item-list {
  display: flex;
  flex-direction: column;
  text-align: center; }

.bm-burger-button {
  position: relative;
  top: 28px;
  left: 20px;
  transition: all 125ms ease; }
  .bm-burger-button:hover {
    opacity: .7; }

.bm-cross-button {
  transition: all 125ms ease; }
  .bm-cross-button:hover {
    opacity: .7; }

@media (max-width: 991px) {
  .MainNavigation {
    padding: 14px 15px; }
    .MainNavigation-link {
      padding-right: 15px;
      font-size: 14px; }
    .MainNavigation-links {
      padding-left: 10px; } }

@media (max-width: 767px) {
  .MainNavigation {
    padding: 14px 20px; }
    .MainNavigation-link {
      padding-right: 0; }
    .MainNavigation-links {
      padding-left: 15px; }
    .MainNavigation-logo {
      padding-left: 15px; }
    .MainNavigation .dropdown-menu {
      right: -65px;
      max-width: calc(100vw - 30px); }
    .MainNavigation .Notification-wrapper-small .dropdown-menu {
      right: -15px; } }

@media (min-width: 1200px) {
  .MainNavigation {
    position: fixed;
    z-index: 1001; } }
