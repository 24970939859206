.BasicParametersBubble {
  background-color: white;
  padding: 15px 40px;
  border-radius: 24px;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 125ms ease;
  border: 2px solid white; }
  .BasicParametersBubble:hover {
    background-color: #f7f7f7;
    border-color: #f7f7f7; }
  .BasicParametersBubble-icon {
    margin: 0 40px;
    display: flex;
    align-items: center;
    max-height: 133px;
    max-width: 116px; }
  .BasicParametersBubble-image {
    max-height: 100%;
    max-width: 100px; }
  .BasicParametersBubble-checkbox {
    display: flex;
    align-items: center; }
  .BasicParametersBubble-body {
    display: flex;
    justify-content: center;
    flex-direction: column; }
  .BasicParametersBubble-spacing--xs {
    height: 4px; }
  .BasicParametersBubble-spacing--md {
    height: 9px; }

.BasicParametersBubble--selected {
  box-shadow: 4px 4px 0 transparent;
  border: 2px solid #E8452C; }
  .BasicParametersBubble--selected:hover {
    border-color: #E8452C; }

@media (max-width: 767px) {
  .BasicParametersBubble {
    flex-direction: column;
    align-items: center; }
    .BasicParametersBubble-checkbox {
      width: 100%;
      justify-content: center;
      margin-bottom: 10px;
      order: 1; }
    .BasicParametersBubble-body {
      order: 2;
      text-align: center;
      margin-bottom: 24px; }
    .BasicParametersBubble-icon {
      order: 3; } }
