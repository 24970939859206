.Reviews-container {
  min-width: 485px;
  max-width: 485px;
  padding-bottom: 20px; }

.Reviews-wrapper {
  background-color: #97C39C;
  color: #ffffff;
  font-weight: bold;
  border-radius: 20px;
  min-height: 105px;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  text-align: left; }

.Reviews-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px; }

.Reviews-photoLeft {
  float: left; }

.Reviews-userPic {
  width: 40px;
  height: 40px;
  border-radius: 35px; }

.Reviews-userInfo {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 10px; }

.Reviews-userRole {
  text-align: left; }

@media (min-width: 1200px) {
  .Reviews-userPic {
    width: 52px;
    height: 52px; } }
