.Button {
  display: inline-block;
  border-radius: 24px;
  padding: 13px 20px;
  cursor: pointer;
  transition: all 250ms;
  border: 0;
  outline: none;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  line-height: 22px;
  font-family: "Matter", "open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  background-color: #E8452C; }
  .Button:active, .Button:focus, .Button:hover {
    color: #fff; }
  .Button:active {
    background-color: #cb2e16; }
  .Button:focus, .Button:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); }

.Button:hover,
.Button:focus {
  text-decoration: none; }

.Button--small {
  font-size: 13px;
  line-height: 18px;
  padding: 10px 15px; }

.Button--sm {
  min-width: 221px; }

.Button--pBox {
  padding: 10px 30px; }

.Button--flex {
  display: flex;
  justify-content: center;
  align-items: center; }

.Button--medium {
  min-width: 260px; }

.Button--large {
  min-width: 291px; }

.Button--semiWide {
  min-width: 250px;
  height: 40px;
  padding: 0; }

.Button--semi {
  height: 37px;
  padding: 10px 15px; }

.Button--xl {
  height: 40px;
  padding: 10px 15px; }

@media (max-width: 360px) {
  .Button--xl {
    padding: 10px 5px; } }

.Button--mini {
  min-width: 184px; }

.Button--xxs {
  min-width: 111px; }

.Button--navigation {
  color: #3C3C3B;
  background-color: #ffffff; }
  .Button--navigation:active, .Button--navigation:focus, .Button--navigation:hover {
    color: #3C3C3B; }
  .Button--navigation:active {
    background-color: rgba(255, 255, 255, 0.75); }
  .Button--navigation:focus, .Button--navigation:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); }

.Button--secondary {
  color: #fff;
  background-color: #4B8795; }
  .Button--secondary:active, .Button--secondary:focus, .Button--secondary:hover {
    color: #fff;
    background-color: #467e8b; }
  .Button--secondary:active {
    background-color: #417581; }
  .Button--secondary:focus, .Button--secondary:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); }

.Button--inverted {
  color: #4B8795;
  background-color: #fff; }
  .Button--inverted:active, .Button--inverted:focus, .Button--inverted:hover {
    color: #467e8b;
    background-color: #f7f7f7; }
  .Button--inverted:active {
    background-color: #f0f0f0; }
  .Button--inverted:focus, .Button--inverted:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); }

.Button--invertedPrimary {
  color: #E8452C;
  background-color: #fff; }
  .Button--invertedPrimary:active, .Button--invertedPrimary:focus, .Button--invertedPrimary:hover {
    color: #e6391e;
    background-color: #f7f7f7; }
  .Button--invertedPrimary:active {
    background-color: #f0f0f0; }
  .Button--invertedPrimary:focus, .Button--invertedPrimary:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); }

.Button--wide {
  display: block;
  width: 100%; }

.Button--half {
  width: 50%; }

.Button--lightGrey {
  background: lightgrey;
  border-radius: 5px;
  color: black;
  padding: 10px 25px;
  text-transform: none; }

.Button--lightGreyMob {
  background: lightgrey;
  border-radius: 5px;
  color: black;
  padding: 10px 30px;
  font-size: 14px;
  text-transform: none; }

.Button--lightGreySm {
  background: lightgrey;
  border-radius: 5px;
  color: black;
  padding: 9px;
  text-transform: none; }

@media (max-width: 360px) {
  .Button--half {
    width: 52%; } }

.Button--lightPrimary {
  background: #ffffff;
  border: 1px solid #F55543;
  color: #F55543;
  padding: 10px 35px; }
  .Button--lightPrimary:hover, .Button--lightPrimary:active, .Button--lightPrimary:focus {
    color: #F55543; }

.Button--smallFunding {
  padding: 5px 10px;
  font-size: 13px;
  background: none;
  border: 1px solid #F55543;
  color: #F55543;
  width: 48.5%;
  border-radius: 5px; }
  .Button--smallFunding:hover, .Button--smallFunding:active, .Button--smallFunding:focus {
    color: #F55543; }

.Button--smallModal {
  padding: 5px 10px;
  font-size: 13px;
  background: none;
  border: 1px solid #F55543;
  color: #F55543;
  width: 20%;
  border-radius: 5px; }
  .Button--smallModal:hover, .Button--smallModal:active, .Button--smallModal:focus {
    color: #F55543; }

.Button.isDisabled,
.Button.isBusy {
  cursor: not-allowed;
  pointer-events: none;
  color: #fff;
  background-color: #bdbdbd; }
  .Button.isDisabled:active, .Button.isDisabled:focus, .Button.isDisabled:hover,
  .Button.isBusy:active,
  .Button.isBusy:focus,
  .Button.isBusy:hover {
    color: #fff;
    background-color: #bdbdbd; }
  .Button.isDisabled:active,
  .Button.isBusy:active {
    background-color: #bdbdbd; }
  .Button.isDisabled:focus, .Button.isDisabled:hover,
  .Button.isBusy:focus,
  .Button.isBusy:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); }

.Button.isBusy {
  cursor: progress; }

.Button--group {
  width: 100%;
  margin: auto;
  background-color: #fff;
  color: #4B8795;
  border-radius: 0;
  padding: 32px;
  font-size: 18px;
  border-bottom: 1px solid #FAEDE5; }
  .Button--group:first-child {
    border-radius: 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .Button--group:last-child {
    border-radius: 25px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none; }
  .Button--group:hover, .Button--group:focus {
    color: #4B8795; }
  .Button--group:active {
    color: white; }

.Button--black.Button--primary,
.Button--primary {
  background-color: #E8452C;
  color: #fff; }
  .Button--black.Button--primary:hover, .Button--black.Button--primary:focus,
  .Button--primary:hover,
  .Button--primary:focus {
    color: #fff;
    background-color: #E8452C; }
  .Button--black.Button--primary:active,
  .Button--primary:active {
    color: white; }

.Button--border {
  border: 1px solid #DBD0C9; }

.Button--smallRadius {
  border-radius: 10px; }

.Button--disableFunding {
  cursor: not-allowed;
  pointer-events: none; }

.Button--middleRadius {
  border-radius: 5px; }

.Button--project {
  min-width: 173px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center; }

.Button--big {
  height: 100px;
  width: 100%;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  font-family: "value-serif-bold", "Times New Roman", Times, serif;
  font-size: 24px; }

.Button--share {
  color: #3C3C3B;
  width: 100%;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 2px 10px;
  font-size: 13px; }
  .Button--share:hover, .Button--share:focus {
    background-color: white;
    color: #4B8795; }

.Button--fb {
  background-color: #3B5998;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center; }
  .Button--fb:hover {
    background-color: #2d4373;
    color: white; }

.Button--succes {
  background-color: white;
  color: #328C69;
  border: 1px solid #328C69;
  padding: 5px 27px;
  font-size: 13px;
  line-height: 20px; }
  .Button--succes:hover {
    background-color: white;
    color: #25664d; }

.Button--wide {
  width: 100%; }

.Button--xs {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 49px;
  padding: 2px 13px;
  min-width: 194px;
  font-size: 13px;
  margin: auto; }

.Button--nav {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px; }

.Button--spacing {
  margin: 0 10px; }

.Button--spacingLeft {
  margin-left: 15px; }

.Button--white {
  background-color: white;
  border: 1px solid #DBD0C9;
  text-transform: uppercase;
  color: #000;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  font-size: 13px; }
  .Button--white:hover, .Button--white:focus {
    color: black;
    background-color: #f7f7f7; }

.Button--paddingSmall {
  padding: 6px; }

.Button--radiusBig {
  border-radius: 49px; }

.Button--cookies {
  font-size: 16px;
  font-weight: bold;
  color: #328C69;
  border: 1px solid #328C69;
  background-color: white;
  border-radius: 49px;
  padding: 8px;
  min-width: 214px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .Button--cookies:hover, .Button--cookies:focus {
    background-color: white;
    color: #328C69; }

.Button--scroll {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: url("/images/svg/scroll.svg") #323232 no-repeat center;
  width: 66px;
  height: 60px;
  border-radius: 100px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 999;
  opacity: .6; }

.Button--round {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #E8452C;
  background-image: url("/images/svg/arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); }

.Button--pagination {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #E8452C;
  background-image: url("/images/svg/arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  background-size: 8px;
  box-shadow: 1px 1px 0 0 rgba(60, 60, 59, 0.16); }

.Button--green {
  color: #fff;
  background-color: #328C69; }
  .Button--green:hover {
    color: #fff;
    background-color: #328C69; }

.Button--grey {
  color: #000000;
  background-color: #fff; }
  .Button--grey:hover {
    color: #000000;
    background-color: #fff; }

.Button--greyPrimary {
  color: #E8452C;
  background-color: #fff; }
  .Button--greyPrimary:hover {
    color: #000000;
    background-color: #fff; }

.Button--invertedGrey {
  color: #fff;
  background-color: #b1b1b1; }
  .Button--invertedGrey:hover {
    color: #fff; }

.Button--invertedBlack {
  color: black;
  background-color: #b1b1b1; }
  .Button--invertedBlack:hover {
    color: #fff; }

.Button--invertedBlue {
  color: #fff;
  background-color: #4B8795; }
  .Button--invertedBlue:hover {
    color: #fff; }

.Button--invertedGreen {
  color: #328C69;
  background-color: #fff; }
  .Button--invertedGreen:hover {
    color: #328C69;
    background-color: #fff; }

.Button--black {
  color: #000000;
  background-color: #fff;
  font-size: 13px;
  padding: 7px 12px;
  margin: 3px 3px;
  border-radius: 16px;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16); }
  .Button--black:hover, .Button--black:focus {
    color: #fff;
    background-color: #E8452C; }

.Button--lowerCase {
  text-transform: lowercase; }
  .Button--lowerCase:first-letter {
    text-transform: capitalize; }

.Button--widePrimary {
  padding: 4px 20px;
  font-size: 13px; }

@media (max-width: 767px) {
  .Button--project {
    margin: auto; }
  .Button--smallModal {
    width: 50%; } }
