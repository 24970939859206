.ProfileScreen-head {
  display: flex;
  padding-bottom: 80px; }

.ProfileScreen-headRight {
  padding-left: 126px;
  flex-grow: 1;
  width: 100%; }

.ProfileScreen-offerTitle {
  font-size: 13px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: #7E7E7B; }

.ProfileScreen-promoText {
  font-size: 18px;
  line-height: 30px;
  color: #3C3C3B;
  padding-top: 45px; }

.ProfileScreen-sectionTitle {
  padding-bottom: 32px; }

.ProfileScreen-box {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 4px 4px 0 0 rgba(60, 60, 59, 0.16); }

.ProfileScreen-boxItem {
  padding: 25px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid rgba(60, 60, 59, 0.16); }

.ProfileScreen-boxItem:last-child {
  border-bottom: 0; }

.ProfileScreen-boxItemName {
  flex-grow: 1;
  display: flex;
  align-items: center; }

.ProfileScreen-boxItemRate {
  display: flex;
  align-items: center;
  padding-right: 15px;
  width: 150px;
  justify-content: flex-end; }

.ProfileScreen-boxItemInfo {
  margin-top: 10px; }

.ProfileScreen-boxItemBtn {
  display: flex;
  align-items: center;
  min-width: 161px; }

.ProfileScreen-boxHelp {
  background-color: #FFF8F4;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  border-radius: 24px;
  padding: 23px 18px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center; }

.ProfileScreen-prices {
  padding-bottom: 80px; }

.ProfileScreen-sampleWorks {
  padding-bottom: 80px; }

.ProfileScreen-info {
  position: absolute;
  top: 76px;
  left: 0;
  width: 100%;
  background: #73E494 url(./img/bg.svg);
  box-shadow: 0 4px 0 0 rgba(60, 60, 59, 0.16);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 85px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  padding: 24px 0; }

.ProfileScreen-topbar {
  position: relative;
  width: 100vw;
  transform: translateX(-50%);
  left: 50%;
  top: -33px;
  margin-bottom: 1px;
  background: #73E494 url(./img/bg.svg);
  box-shadow: 0 4px 0 0 rgba(60, 60, 59, 0.16);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 85px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  padding: 24px 0; }

.ProfileScreen-infoStep {
  height: 120px; }

.ProfileScreen-references--spacing {
  margin-top: 90px; }

.handshake-info {
  background: rgba(128, 255, 126, 0.1);
  mix-blend-mode: normal;
  padding: 0 20px; }
  .handshake-info p {
    color: #000;
    line-height: 24px;
    font-size: 16px; }

.profile-page h1 {
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 4rem; }

.profile-page h4 {
  font-weight: bold;
  font-size: 2rem; }

.profile-page .title-container {
  padding: 0 0 10px;
  border-bottom: 2px solid #F2F2F2;
  width: 100%; }

.profile-page .avatar {
  width: 228px;
  height: 228px; }

.profile-page button {
  width: 175px;
  height: 38px;
  border-radius: 20px; }
  .profile-page button .button-label .edit-pencil {
    width: 20px;
    height: 20px; }

.profile-page .person-info {
  display: flex;
  align-items: center;
  min-height: 320px; }
  .profile-page .person-info .left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .profile-page .person-info .right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: left; }
  .profile-page .person-info .info {
    display: flex;
    flex-direction: column; }
    .profile-page .person-info .info h3, .profile-page .person-info .info span {
      width: 100%; }
    .profile-page .person-info .info h3 {
      font-weight: bold;
      font-size: 3rem; }
    .profile-page .person-info .info span {
      font-size: 1.8rem; }
    .profile-page .person-info .info > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .profile-page .person-info .info > div span {
        color: #999999;
        font-size: 1.6rem; }
    .profile-page .person-info .info .roles-container {
      padding: 0;
      display: flex;
      flex-direction: column; }
      .profile-page .person-info .info .roles-container .cooperation {
        background: #EEEEEE;
        border-radius: 15px;
        min-height: 12px;
        font-weight: bold;
        max-width: 125px;
        font-size: 13px;
        padding: 10px 5px;
        cursor: pointer;
        margin-top: 10px; }
      .profile-page .person-info .info .roles-container .user-role {
        background: #000000;
        border-radius: 15px;
        color: #fff;
        text-align: center;
        max-width: 125px;
        min-height: 12px;
        font-size: 13px;
        padding: 5px; }

.profile-page .category {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.ProfileScreen-editSpacing {
  margin-bottom: 55px; }

.ProfileScreen-editSpacing--small {
  margin-bottom: 25px; }

.ProfileScreen-editSpacing--xs {
  margin-bottom: 21px; }

@media (max-width: 767px) {
  .ProfileScreen-head {
    flex-wrap: wrap; }
  .ProfileScreen-headRight {
    padding: 0;
    margin-top: 20px; }
  .ProfileScreen-headLeft {
    display: flex;
    justify-content: center;
    width: 100%; }
  .ProfileScreen-info {
    padding: 24px; }
  .ProfileScreen-infoSpacingMd {
    height: 15px; }
  .ProfileScreen-infoStep {
    height: 180px; }
  .ProfileScreen-infoStep--big {
    height: 350px; }
  .ProfileScreen-boxHelp {
    margin-top: 10px; }
  .ProfileScreen-boxItem {
    flex-wrap: wrap; }
  .ProfileScreen-boxItemRate {
    justify-content: flex-start;
    margin-top: 5px; }
  .ProfileScreen-boxItemBtn {
    margin-top: 15px; }
  .ProfileScreen-infoBtn {
    margin-top: 15px; } }

.ProfileScreen-form .has-feedback label ~ .form-control-feedback {
  top: 54px;
  right: 10px; }
