.DocumentWizardPage {
  min-height: 100px; }
  .DocumentWizardPage-navigation {
    position: absolute;
    top: 76px;
    left: 0;
    width: 100%;
    background: #E8452C url(./img/navigation.svg);
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    height: 128px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold; }
  .DocumentWizardPage-navigationSpacing {
    height: 110px; }
  .DocumentWizardPage-title {
    margin-bottom: 26px; }
  .DocumentWizardPage-submit {
    margin-top: 65px; }
  .DocumentWizardPage-tip {
    margin-top: 41px;
    display: none; }
  .DocumentWizardPage-tip--active {
    display: block; }
  .DocumentWizardPage-back {
    margin-top: 26px;
    color: #7E7E7B;
    font-size: 16px; }
    .DocumentWizardPage-back span {
      cursor: pointer; }
      .DocumentWizardPage-back span:hover {
        color: #3C3C3B; }
