.OurBenefits-number {
  color: #EB3F2E;
  font-weight: bold;
  width: 35px;
  height: 35px;
  background: #ffffff;
  border-radius: 25px;
  border: 2px solid #EB3F2E;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 15px;
  z-index: 10;
  margin-right: 10px;
  padding: 15px; }

.advantageTextContainer > p {
  display: inline; }
