.Tag {
  margin: 0;
  padding: 8px 16px;
  line-height: 1;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  color: #4B8795;
  box-shadow: 2px 2px 0 0 rgba(60, 60, 59, 0.16); }
  .Tag.isActive {
    background-color: #000;
    color: #B1B1B1; }

.Tag--secondary {
  color: #4B8795;
  padding: 8px 16px;
  text-transform: lowercase; }
  .Tag--secondary:first-letter {
    text-transform: uppercase; }
