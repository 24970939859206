.Input {
  height: 48px;
  border: 2px solid rgba(60, 60, 59, 0.16);
  border-radius: 4px;
  padding-left: 16px;
  font-size: 16px;
  color: #3C3C3B;
  outline: none;
  text-align: left; }
  .Input-areaWrapper {
    width: 75%;
    position: relative;
    margin: auto; }
  .Input-areaWrapper--hasMax {
    padding-bottom: 20px; }
  .Input-areaWrapper--wide {
    width: 100%; }
  .Input-max {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    color: #7E7E7B;
    font-size: 16px; }
  .Input.priceList {
    min-width: 100px;
    height: 38px;
    padding: 0;
    text-align: right;
    margin-right: 5px; }
  .Input.input-annotation {
    font-size: 15px; }
  .Input ::placeholder {
    color: #7E7E7B; }
  .Input:-ms-input-placeholder {
    color: #7E7E7B; }
  .Input ::-ms-input-placeholder {
    color: #7E7E7B; }

.Input--area {
  text-align: left;
  font-weight: normal;
  resize: none;
  height: 220px;
  background-color: white;
  padding: 13px 15px;
  font-size: 16px;
  width: 100%;
  border: 2px solid rgba(60, 60, 59, 0.16); }
  .Input--area.small {
    height: auto;
    min-height: 132px; }

.Input--areaXs {
  text-align: left;
  font-weight: normal;
  resize: none;
  height: 110px;
  background-color: white;
  padding: 13px 15px;
  font-size: 16px;
  width: 100%;
  border: 2px solid rgba(60, 60, 59, 0.16); }
  .Input--areaXs.bold {
    font-weight: bold; }

.Input--wide {
  width: 100%; }

.Input-currency {
  margin-left: -35px;
  z-index: 10;
  margin-top: 8px; }
