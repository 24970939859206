.ProjectPrices {
  border-radius: 24px;
  width: 100%;
  box-shadow: 4px 4px 0 0 rgba(60, 60, 59, 0.16);
  background-color: #fff;
  padding: 20px 24px 32px;
  min-height: 110px; }
  .ProjectPrices-line {
    height: 1px;
    width: 100%;
    background-color: #ececec;
    margin-top: 20px;
    margin-bottom: 20px; }
  .ProjectPrices-spacing--xs {
    height: 5px; }
  .ProjectPrices-spacing--sm {
    height: 14px; }
  .ProjectPrices-spacing--lg {
    height: 30px; }
  .ProjectPrices-spacing--xl {
    height: 60px; }

.ProjectPrices-financingProgress {
  max-width: 100%; }

.ProjectPrices-financingStatus {
  padding: 8px 24px 24px;
  margin-left: -24px;
  margin-right: -24px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0 1px 0 0 rgba(60, 60, 59, 0.16); }

.ProjectPrices-financingStatusRow {
  display: flex;
  padding-top: 4px; }

.ProjectPrices-financingStatusKey {
  color: #3C3C3B; }

.ProjectPrices-financingStatusValue {
  flex-grow: 1;
  color: #4B8795;
  text-align: right; }

.ProjectPrices-progressStatus {
  font-size: 16px;
  line-height: 24px; }

.ProjectPrices-progressStatusRow {
  display: flex;
  color: #7E7E7B;
  padding-top: 5px; }

.ProjectPrices-progressStatusValue {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end; }

.ProjectPrices-progressStatusIcon {
  width: 30px;
  height: 30px;
  background: transparent url(./img/waiting.svg) no-repeat center; }

.ProjectPrices-progressStatusRow--waiting {
  color: #7E7E7B; }
  .ProjectPrices-progressStatusRow--waiting .ProjectPrices-progressStatusIcon {
    background-image: url(./img/waiting.svg); }

.ProjectPrices-progressStatusRow--done {
  color: #3C3C3B; }
  .ProjectPrices-progressStatusRow--done .ProjectPrices-progressStatusIcon {
    background-image: url("/images/svg/done.svg"); }

.ProjectPrices-progressStatusRow--inProgress {
  color: #3C3C3B; }

.ProjectPrices-progressStatusRow--proposal {
  color: #3C3C3B;
  font-weight: bold; }

.ProjectPrices-progressStatusIcon {
  background-image: url(./img/proposal.svg); }

.ProjectPrices-progressStatusRow--funding {
  color: #3C3C3B; }
  .ProjectPrices-progressStatusRow--funding .ProjectPrices-progressStatusIcon {
    background-image: url("/images/svg/funding.svg"); }

.ProjectPrices-background {
  border-radius: 24px 24px 0 0;
  padding: 28px;
  margin: -28px -24px -28px -24px; }

.ProjectPrices-background--making {
  background-color: #b1b1b1; }

.ProjectPrices-background--released {
  background-color: #E8452C; }

.ProjectPrices-background--financing {
  background-color: #328C69; }

.ProjectPrices-background--inProgress {
  background-color: #4B8795;
  min-height: 140px; }

.ProjectPrices-financingProgress {
  max-width: 100%; }

@media (max-width: 767px) {
  .ProjectPrices {
    margin-top: 20px; } }
