.UserHomePage-head {
  margin-top: -35px; }

.UserHomePage-row:last-child {
  background: #FAEDE6;
  margin: 0 -13.2%;
  padding: 40px 12% 0 12%; }

.UserHomePage-rowHeading {
  font-weight: bold; }

.UserHomePage-headWrap {
  margin-left: -30px;
  margin-right: -30px; }

.UserHomePage-panelBox {
  padding: 0 30px;
  margin-top: 20px; }

.UserHomePage-introButton {
  width: 82%; }

.UserHomePage-typeHeadline {
  float: right;
  width: 50%; }

.UserHomePage-panel {
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  padding: 50px 30px;
  background-color: #FFF8F4;
  border-radius: 24px; }

.UserHomePage-panelButton {
  position: absolute;
  bottom: 5px;
  right: 80px; }

.UserHomePage-baseInfoWrapper {
  height: auto;
  background: #C2DDC4;
  margin: 0 -1500px;
  padding: 0 1500px 30px 1500px; }

.UserHomePage-introImageWrapper {
  float: none;
  text-align: center; }

.UserHomePage-introImage {
  width: 325px;
  padding: 10px 0; }

.UserHomePage-introButton {
  width: 100%;
  text-align: center; }

.UserHomePage-headlineStep {
  height: 16px; }

.UserHomePage-headlineStep--small {
  height: 8px; }

.UserHomePage-headlineStep--medium {
  height: 29px; }

.UserHomePage-headlineStep--large {
  height: 40px; }

.UserHomePage-headlineStep--semi {
  height: 80px; }

.UserHomePage-headlineStep--xl {
  height: 100px; }

@media (min-width: 1200px) {
  .UserHomePage-panelBox {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 35px;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 0; }
  .UserHomePage-introImageWrapper {
    float: right; }
  .UserHomePage-introImage {
    width: 465px; }
  .UserHomePage-introButton {
    text-align: left;
    float: left;
    width: 100%; }
  .UserHomePage-typeHeadline {
    float: none;
    width: 100%; }
  .UserHomePage-headWrap {
    margin-top: -55px; } }

@media (min-width: 1200px) {
  .UserHomePage-head {
    margin-top: 0; } }
