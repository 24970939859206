@charset "UTF-8";
.ProjectCosts {
  width: 100%;
  background-color: white;
  border-radius: 24px;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  padding: 38px 24px; }
  .ProjectCosts-title {
    text-align: center;
    padding-bottom: 25px;
    border-bottom: 1px solid #ececec;
    margin-bottom: 15px; }
  .ProjectCosts-price {
    padding-top: 25px;
    margin-top: 15px;
    border-top: 1px solid #ececec; }
  .ProjectCosts-arrow {
    float: right;
    transition: all 400ms ease;
    cursor: pointer;
    margin-right: 5px; }
  .ProjectCosts-arrow--down {
    transform: rotateX(180deg); }
  .ProjectCosts.flippingMode {
    transform: perspective(1000px) rotateY(180deg); }
    .ProjectCosts.flippingMode.flipped {
      transform: perspective(1000px) rotateY(0deg); }
  .ProjectCosts .\←toFundingSelect {
    cursor: pointer;
    display: block;
    margin-bottom: 1em; }
  .ProjectCosts-arrow--hidden {
    display: none; }

.costflippingModeTransition {
  transition: transform 0.5s ease;
  webkit-backface-visibility: hidden;
  backface-visibility: hidden; }
