.UploadFile-files .mobileSlider-slider {
  width: 100%;
  margin-left: 15px; }

.UploadFile-files .mobileSlider-slide {
  margin-right: 24px;
  margin-bottom: 24px; }

.slick-track {
  margin: 0; }

.slick-next {
  z-index: 50;
  right: 5px; }
  @media not all and (min-resolution: 0.001dpcm) {
    .slick-next {
      height: 100%;
      width: 2.2%;
      right: 5px; } }

.slick-prev {
  z-index: 50;
  left: -10px; }

.slick-dots {
  background: white;
  position: relative;
  height: 36px;
  bottom: 0; }

.slick-prev:before,
.slick-next:before {
  color: #305c51;
  font-size: 30px;
  opacity: 2; }

.slick-dots li.slick-active button:before {
  color: #8cbb91;
  background: #8cbb91;
  opacity: 2; }

.slick-dots li button:before {
  color: white;
  border: 1px solid #8cbb91;
  opacity: 1;
  border-radius: 25px;
  font-size: 12px;
  width: 12px;
  height: 12px;
  line-height: 12px; }

.ProjectsScreen .mobileSlider-slide {
  height: 100%; }

.NewLandingPage .mobileSlider-slide {
  height: 100%;
  padding: 0 7px; }

.UploadFile-file--samples {
  height: initial; }

.mobileSlider-slide,
.slick-track,
.slick-list,
.ProjectsScreen .slick-initialized .slick-slide {
  display: flex; }

.ProjectsScreen .slick-initialized .slick-slide > div {
  width: 100%;
  float: left; }

.mobileSlider-slide,
.slick-track,
.slick-list,
.NewLandingPage .slick-initialized .slick-slide {
  display: flex; }

.NewLandingPage .slick-initialized .slick-slide > div {
  width: 100%;
  float: left; }
