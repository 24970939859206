.ContactPage {
  margin-bottom: 20px; }
  .ContactPage-line {
    width: 60%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;
    background-color: #DBD0C9; }
  .ContactPage-imageBox {
    background-image: url("./img/bg.svg");
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    min-height: 390px;
    padding: 45px; }
  .ContactPage-image {
    max-width: 100%; }
  .ContactPage-spacing--sm {
    height: 25px; }
  .ContactPage-spacing--lg {
    height: 55px; }

@media (max-width: 1199px) {
  .ContactPage-imageBox {
    min-height: 100px; } }
