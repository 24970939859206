.FollowToggler {
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  background-color: white;
  padding: 10px;
  justify-content: center;
  text-align: center;
  border-radius: 24px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: filter 125ms ease, opacity 125ms ease; }
  .FollowToggler-followImage {
    height: 30px;
    margin-right: 24px;
    transition: filter 125ms ease, opacity 125ms ease; }
  .FollowToggler-bookmark {
    cursor: pointer;
    align-self: flex-start;
    width: 25px;
    flex-shrink: 0;
    margin-left: 10px;
    transition: filter 125ms ease, opacity 125ms ease; }
    .FollowToggler-bookmark:hover {
      filter: brightness(0.4); }
  .FollowToggler-bookmark--followed:hover {
    opacity: .6;
    filter: none; }
  .FollowToggler:hover .FollowToggler-followImage {
    filter: brightness(0.4); }

.FollowToggler--followed {
  color: #E8452C; }
  .FollowToggler--followed:hover .FollowToggler-followImage {
    filter: none;
    opacity: .6; }

@media (max-width: 767px) {
  .FollowToggler {
    margin-top: 24px; } }
