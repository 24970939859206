.WelcomePanel {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  background: #79ce72 url(./img/bg2.svg);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  min-height: 128px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  padding: 20px 0;
  margin-bottom: 40px; }
  .WelcomePanel-list {
    margin-top: 10px; }
  .WelcomePanel-listItem {
    font-size: 16px;
    font-weight: 400; }

@media (max-width: 767px) {
  .WelcomePanel {
    padding: 24px 15px 40px; }
    .WelcomePanel-img {
      margin-bottom: 17px; }
    .WelcomePanel-headline {
      margin-bottom: 20px; } }
