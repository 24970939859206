.ProjectStateRow-rowHeading {
  font-weight: bold; }

.ProjectStateRow-headlineStep {
  height: 16px; }

.ProjectStateRow-headlineStep--small {
  height: 8px; }

.ProjectStateRow-headlineStep--medium {
  height: 29px; }

.ProjectStateRow-headlineStep--large {
  height: 40px; }

.ProjectStateRow-headlineStep--semi {
  height: 80px; }

.ProjectStateRow-headlineStep--xl {
  height: 100px; }
