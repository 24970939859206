.ProjectsPage-head {
  margin-top: -35px; }

.ProjectsPage-row:last-child {
  background: #FAEDE6;
  margin: 0 -13.2%;
  padding: 40px 12% 0 12%; }

.ProjectsPage-rowHeading {
  font-weight: bold; }

.ProjectsPage-headWrap {
  margin-left: -30px;
  margin-right: -30px; }

.ProjectsPage-select {
  width: auto;
  max-width: 500px;
  min-width: 244px; }

.ProjectsPage-panelBox {
  padding: 0 30px;
  margin-top: 20px; }

.ProjectsPage-introButton {
  width: 82%; }

.ProjectsPage-typeHeadline {
  float: right;
  width: 50%; }

.ProjectsPage-panel {
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  padding: 50px 30px;
  background-color: #FFF8F4;
  border-radius: 24px; }

.ProjectsPage-panelButton {
  position: absolute;
  bottom: 5px;
  right: 80px; }

.ProjectsPage-baseInfoWrapper {
  height: auto;
  background: #C2DDC4;
  margin: 0 -1500px;
  padding: 0 1500px 30px 1500px; }

.ProjectsPage-introImageWrapper {
  float: none;
  text-align: center; }

.ProjectsPage-introImage {
  width: 325px;
  padding: 10px 0; }

.ProjectsPage-headlineStep {
  height: 16px; }

.ProjectsPage-headlineStep--small {
  height: 8px; }

.ProjectsPage-headlineStep--medium {
  height: 29px; }

.ProjectsPage-headlineStep--large {
  height: 40px; }

.ProjectsPage-headlineStep--semi {
  height: 80px; }

.ProjectsPage-headlineStep--xl {
  height: 100px; }

@media (min-width: 1200px) {
  .ProjectsPage-panelBox {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 35px;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 10px; }
  .ProjectsPage-select {
    width: auto;
    max-width: 500px;
    min-width: 244px; }
  .ProjectsPage-introImageWrapper {
    float: right; }
  .ProjectsPage-introImage {
    width: 465px; }
  .ProjectsPage-introButton {
    float: left;
    width: 100%; }
  .ProjectsPage-typeHeadline {
    float: none;
    width: 100%; } }

@media (min-width: 1200px) {
  .ProjectsPage-head {
    margin-top: 0; } }
