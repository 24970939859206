.MainNavigation .dropdown-toggle {
  background: transparent;
  outline: none;
  border: none;
  box-shadow: unset; }
  .MainNavigation .dropdown-toggle:active, .MainNavigation .dropdown-toggle:hover {
    box-shadow: unset;
    background-color: transparent; }
  .MainNavigation .dropdown-toggle:after {
    display: none; }

.MainNavigation .dropdown-menu {
  border-radius: 24px;
  left: auto;
  right: -76px;
  top: 75px;
  width: 440px;
  border: none;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  padding: 22px 0 28px 0; }
  .MainNavigation .dropdown-menu:before {
    bottom: 100%;
    right: 100px;
    border: solid rgba(255, 255, 255, 0);
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px; }
