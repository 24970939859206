.Pagination-container {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 20px; }

.Pagination-button {
  list-style: none;
  display: flex;
  align-items: center; }

.Pagination-button--prev {
  margin-right: 28px;
  transform: rotate(180deg); }

.Pagination-button--next {
  margin-left: 28px; }

.Pagination-item {
  list-style: none;
  color: #7E7E7B;
  font-size: 16px;
  font-weight: bold;
  margin: 0 7px;
  cursor: pointer; }
  .Pagination-item a {
    color: #7E7E7B;
    text-decoration: none; }
    .Pagination-item a:focus, .Pagination-item a:hover, .Pagination-item a:active {
      outline: none; }

.Pagination-item--active a {
  color: #E8452C;
  text-decoration: underline; }

.Pagination-item--break {
  pointer-events: none;
  cursor: default;
  margin: 0;
  font-size: 14px; }

.Pagination-step--active {
  color: #E8452C;
  text-decoration: underline; }
