.RegisterPage {
  padding-top: 65px; }
  .RegisterPage-headlineSpacing {
    height: 32px; }
  .RegisterPage-formSpacing {
    height: 54px; }
  .RegisterPage-buttonSpacing {
    height: 15px; }
  .RegisterPage-linkSpacing {
    height: 10px; }
  .RegisterPage-body {
    margin-top: 27px;
    margin-bottom: 20px; }
  .RegisterPage-how--second .RegisterPage-howNumber {
    color: #ed4b31; }
  .RegisterPage-how--third .RegisterPage-howNumber {
    color: #d48e67; }
  .RegisterPage-how--fourth .RegisterPage-howNumber {
    color: #328c69; }
  .RegisterPage-how--fifth .RegisterPage-howNumber {
    color: #cd7e93; }
  .RegisterPage-back {
    cursor: pointer; }
  .RegisterPage-how {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .RegisterPage-how .RegisterPage-howNumber {
      color: #4B8795; }
  .RegisterPage-how--second .RegisterPage-howNumber {
    color: #ed4b31; }
  .RegisterPage-how--third .RegisterPage-howNumber {
    color: #d48e67; }
  .RegisterPage-how--fourth .RegisterPage-howNumber {
    color: #328c69; }
  .RegisterPage-how--fifth .RegisterPage-howNumber {
    color: #cd7e93; }
  .RegisterPage-howImage {
    margin-right: 32px;
    max-width: 75px; }
  .RegisterPage-block {
    background-color: white;
    border-radius: 24px;
    box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
    padding: 20px; }

.RegisterPage-howNumber {
  color: #4B8795; }
