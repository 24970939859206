.select-input {
  outline: none;
  font-size: 1.3rem;
  color: #000;
  min-width: 80px; }
  .select-input .select-input__value-container {
    height: 38px; }
    .select-input .select-input__value-container .select-input__single-value {
      color: #000;
      font-size: 1.8rem; }
  .select-input .select-input__placeholder {
    color: #c1c1c1;
    font-size: 1.5rem; }
  .select-input .select-input__indicator-separator {
    display: none; }
  .select-input .select-input__dropdown-indicator {
    color: #000; }
  .select-input .select-input__control {
    height: 38px;
    border: 1px solid rgba(60, 60, 59, 0.16);
    background-color: white; }
  .select-input .select-box__control {
    background-color: white;
    border: 1px solid rgba(60, 60, 59, 0.16);
    border-radius: 8px; }
