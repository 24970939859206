.DetailBox-detailBoxContainer {
  background: #ffffff;
  width: 700px;
  border-radius: 23px;
  height: auto;
  margin: auto;
  display: block;
  padding: 0px 15px 50px 15px; }

.DetailBox-detailBoxHeader {
  background: lightblue;
  margin: 0 -15px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px; }

.DetailBox-infoNotifWrapper {
  text-align: center;
  margin-bottom: 15px; }

.DetailBox-infoNotif {
  height: 30px;
  margin: 10px 0;
  padding-left: 43px; }

.DetailBox-buttonSent {
  float: right;
  margin: 0 10px; }
