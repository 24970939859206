.Link {
  color: #E8452C;
  text-decoration: none; }

.Link:hover, .Link:focus {
  color: #E8452C; }

.Link--white {
  color: white;
  text-decoration: none; }

.Link--light {
  color: #7E7E7B;
  text-decoration: none; }

.Link--bold {
  font-weight: bold; }

.Link--align {
  vertical-align: middle; }

.Link--greenReward {
  color: #38675B; }

.Link--regular {
  color: #3D3D3C; }

.Link--small {
  font-size: 13px; }

.Link--medium {
  font-size: 14px; }

.Link--big {
  font-size: 18px; }

.Link--uppercase {
  text-transform: uppercase; }

.Link--underline {
  text-decoration: underline; }

.Link--leftSide {
  float: left; }

@media (max-width: 767px) {
  .Link--paddingMob {
    padding: 8px; } }
