.Dashboard-hero {
  margin-left: -1000px;
  margin-right: -1000px;
  margin-top: -33px;
  position: relative;
  overflow: hidden; }
  .Dashboard-hero:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -315px;
    left: -50%;
    z-index: -1;
    transform: scaleX(-1) rotateX(-7deg);
    background-image: url("./img/bg.svg");
    background-size: 1795px;
    background-repeat: no-repeat;
    background-position: top; }

.Dashboard-hero--author:before {
  background-image: url("./img/bg--secondary.svg");
  transform: rotateX(-7deg); }

.Dashboard-heroContent {
  max-width: 1140px;
  margin: auto;
  padding-top: 90px;
  padding-bottom: 80px;
  min-height: 450px; }

.Dashboard-select {
  width: 244px; }

.Dashboard-select--big {
  width: auto;
  max-width: 500px;
  min-width: 244px; }

.Dashboard-spacing--xxl {
  height: 138px; }

.Dashboard-spacing--xl {
  height: 80px; }

.Dashboard-spacing--lg {
  height: 25px; }

.Dashboard-spacing--md {
  height: 15px; }

.Dashboard-spacing--sm {
  height: 10px; }

.Dashboard-spacing--xs {
  height: 8px; }

@media (max-width: 991px) {
  .Dashboard-select--big {
    max-width: 300px; } }

@media (max-width: 767px) {
  .Dashboard-select {
    width: auto;
    max-width: 100%; }
  .Dashboard-heroContent {
    max-width: calc(100vw - 30px);
    padding-top: 20px; } }
