.HowToContributor-number {
  color: #EB3F2E;
  font-weight: bold;
  width: 32px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 75px;
  margin-right: 15px; }

.HowToContributor-middleSquareOne {
  position: absolute;
  margin-top: 200px;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  z-index: 10; }

.HowToContributor-middleSquareTwo {
  position: absolute;
  margin-top: 400px;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  z-index: 10; }

.HowToContributor-middleSquareThree {
  position: absolute;
  margin-top: 600px;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  z-index: 10; }

.HowToContributor-middleSquareFour {
  position: absolute;
  margin-top: 800px;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  z-index: 10; }

.HowToContributor-arrowSymbol {
  position: absolute;
  margin-top: 1036px;
  margin-left: 0.3px;
  color: #EB3F2E;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  z-index: 10; }

.HowToContributor-middleLine {
  color: white;
  font-weight: bold;
  width: 30px;
  height: 30px;
  background: #EB3F2E;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 15px;
  z-index: 10; }
  .HowToContributor-middleLine:after {
    content: "";
    position: absolute;
    height: 100%;
    border: 2px dashed #EB3F2E;
    z-index: -2;
    top: 33px; }
