.OnboardingScreen {
  text-align: center; }
  .OnboardingScreen-block {
    padding: 56px 115px;
    background-color: #FAEDE5;
    border-radius: 25px;
    margin-bottom: 30px; }
  .OnboardingScreen-textBlock {
    max-width: 690px;
    margin: auto; }
  .OnboardingScreen-block--wide {
    padding: 30px; }
  .OnboardingScreen-navigation {
    position: absolute;
    top: 76px;
    left: 0;
    width: 100%;
    background: #E8452C url(./img/navigation.svg);
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    height: 128px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold; }
  .OnboardingScreen-back {
    font-size: 16px;
    color: #7E7E7B;
    line-height: 24px;
    text-align: center;
    margin-top: 26px;
    cursor: pointer; }
    .OnboardingScreen-back:hover {
      color: #3C3C3B; }
  .OnboardingScreen-navigationSpacing {
    height: 206px; }
  .OnboardingScreen-headlineStep {
    height: 24px; }
  .OnboardingScreen-headlineStep--md {
    height: 30px; }
  .OnboardingScreen-headlineStep--medium {
    height: 37px; }
  .OnboardingScreen-headlineStep--large {
    height: 73px; }
  .OnboardingScreen-avatarStep {
    height: 18px; }
  .OnboardingScreen-blockStep {
    height: 50px; }
  .OnboardingScreen-blockStep--large {
    height: 68px; }
  .OnboardingScreen-blockStep--small {
    height: 40px; }
  .OnboardingScreen-blockStep--smaller {
    height: 30px; }

@media (max-width: 767px) {
  .OnboardingScreen-navigation {
    align-items: flex-start;
    padding-top: 25px;
    height: 165px; }
  .OnboardingScreen-block {
    padding: 30px 15px; } }

@media (max-width: 414px) {
  .OnboardingScreen-headlineStep--large {
    height: 40px; }
  .OnboardingScreen-headlineStep--mobile {
    height: 10px; } }
