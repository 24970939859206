.ProjectInfo {
  border-radius: 24px;
  padding: 25px;
  background-color: #fff;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  transition: all 250ms ease;
  justify-content: flex-start;
  min-height: 380px; }
  .ProjectInfo-bibliography {
    font-size: 14px;
    display: flex;
    align-items: center; }
  .ProjectInfo-coverPhoto {
    float: left;
    max-width: 80px;
    width: auto;
    height: auto;
    display: flex;
    align-self: flex-start;
    margin-right: 10px;
    margin-bottom: 10px; }
  .ProjectInfo-book {
    padding: 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%; }
  .ProjectInfo-button {
    flex-shrink: 0; }
  .ProjectInfo:hover {
    background-color: #f7f7f7; }
  .ProjectInfo-headline {
    display: flex;
    justify-content: space-between; }
  .ProjectInfo-news {
    display: flex;
    border-top: 1px solid #ececec;
    padding: 13px 0;
    margin-top: auto;
    justify-content: space-between; }
  .ProjectInfo-progress {
    padding-top: 15px;
    margin-top: auto; }
  .ProjectInfo-progress--followed {
    margin-top: 0; }
  .ProjectInfo-progress--emptyNews {
    margin-top: auto; }

.ProjectInfo--eshop {
  background-image: url("/images/eshop.png");
  background-position: center bottom 25px;
  background-repeat: no-repeat;
  background-color: #D1C8C2;
  min-height: 440px;
  position: relative;
  justify-content: flex-start;
  cursor: default; }
  .ProjectInfo--eshop:hover {
    background-color: #D1C8C2;
    cursor: pointer; }
  .ProjectInfo--eshop:after {
    content: "";
    position: absolute;
    width: calc(100% + 20px);
    height: 233px;
    background-image: url("/images/svg/buybook.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    top: 50%;
    left: -10px;
    transform: translateY(-50%); }
  .ProjectInfo--eshop .ProjectInfo-headline {
    height: auto; }

.ProjectInfo--book {
  background-color: #E8452C;
  justify-content: flex-start;
  cursor: default; }
  .ProjectInfo--book:hover {
    background-color: #E8452C; }
  .ProjectInfo--book .ProjectInfo-headline {
    height: auto; }

.ProjectInfo--wrapper {
  margin-left: 15px;
  margin-right: 15px; }

.ProjectInfo-genres {
  font-size: 14px;
  font-weight: bold;
  color: #7E7E7B;
  flex-shrink: 0; }

.ProjectInfo-tags {
  flex-shrink: 0; }

.ProjectInfo-authors {
  display: block;
  margin-top: 15px; }

.ProjectInfo-author {
  display: flex; }

.ProjectInfo-authorAvatar {
  width: 32px;
  height: 32px; }

.ProjectInfo-authorName {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px; }

.ProjectInfo-collected {
  color: #4B8795; }

.ProjectInfo-remaining {
  color: #7E7E7B; }

.ProjectInfo-spacing--xs {
  height: 5px; }

.ProjectInfo-spacing--sm {
  height: 10px; }

.ProjectInfo-spacing--md {
  height: 15px; }

.ProjectInfo-background {
  border-radius: 0 0 24px 24px;
  padding: 10px 25px 12px 25px;
  margin: -25px; }

.ProjectInfo-background--financing {
  background-color: #328C69; }

.ProjectInfo-background--inProgress {
  background-color: #4B8795; }

.ProjectInfo-background--making {
  background-color: #b1b1b1; }

.ProjectInfo-background--released {
  background-color: #E8452C; }

@media (max-width: 414px) {
  .ProjectInfo--eshop {
    min-height: 465px; } }
