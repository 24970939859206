.ProjectProgress {
  border-radius: 24px;
  width: 100%;
  box-shadow: 4px 4px 0 0 rgba(60, 60, 59, 0.16);
  background-color: #fff;
  padding: 20px 24px 32px; }
  .ProjectProgress-bibliography {
    width: 100%; }
  .ProjectProgress-coverPhoto {
    display: block;
    width: 100%;
    height: auto;
    margin: 5px 0; }
  .ProjectProgress-borderLine {
    position: relative;
    bottom: 65px; }

@media (max-width: 767px) {
  .ProjectPrices {
    margin-top: 20px; } }
