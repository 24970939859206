.AuthorCard-container {
  padding-top: 0; }

.AuthorCard-wrapper {
  color: #38675B;
  background-color: #ffffff;
  font-size: 16px;
  border-radius: 25px;
  padding: 15px 15px 15px 0;
  margin: 20px 15px 0px 15px;
  min-height: 155px;
  max-height: 155px;
  transition: all 250ms ease; }
  .AuthorCard-wrapper:hover {
    box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16); }

.AuthorCard-info {
  width: 65%;
  margin-left: 35%;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 100px;
  overflow: hidden;
  margin-left: -20px; }

.AuthorCard-photoSide {
  float: left;
  height: 155px;
  width: 100px;
  margin-top: -15px; }

.AuthorCard-imgLeftPrimary {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 100%;
  width: 100%;
  object-fit: cover; }

.AuthorCard-containerGroup {
  padding-top: 0; }

.AuthorCard-wrapperGroup {
  color: #38675B;
  background-color: #ffffff;
  font-size: 16px;
  border-radius: 25px;
  padding: 15px 15px 15px 0;
  margin: 20px 15px 20px 15px;
  min-height: 155px;
  max-height: 155px;
  transition: all 250ms ease; }
  .AuthorCard-wrapperGroup:hover {
    box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16); }

.AuthorCard-infoGroup {
  width: 65%;
  margin-left: 35%;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 100px;
  overflow: hidden;
  margin-left: -10px; }

.AuthorCard-photoSideGroup {
  float: left;
  height: 155px;
  width: 100px;
  margin-top: -15px; }

.AuthorCard-imgLeftGroup {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 100%;
  width: 100%;
  object-fit: cover; }

.AuthorCard-moreButton {
  padding-left: 10px; }

.AuthorCard-moreButtonGroup {
  padding-left: 10px; }

@media (max-width: 360px) {
  .AuthorCard-info {
    width: 55%;
    padding-left: 10px; }
  .AuthorCard-infoGroup {
    width: 55%;
    padding-left: 10px; } }

@media (min-width: 1200px) {
  .AuthorCard-container {
    padding-top: 15px; }
  .AuthorCard-wrapper {
    color: #38675B;
    background-color: #ffffff;
    font-size: 16px;
    border-radius: 25px;
    padding: 15px 15px 15px 0;
    width: 100%;
    float: left;
    margin: 20px 15px 20px 0;
    min-height: 255px;
    max-height: 255px; }
  .AuthorCard-info {
    width: 44%;
    margin-left: 50%;
    display: flex;
    flex-direction: column;
    min-height: 210px;
    max-height: 210px;
    overflow: hidden;
    padding-left: 0; }
  .AuthorCard-photoSide {
    height: 255px;
    width: 450px;
    margin-top: -15px;
    float: left; }
  .AuthorCard-imgLeft {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .AuthorCard-moreButton {
    padding-left: 0; }
  .AuthorCard-containerGroup {
    display: flex;
    justify-content: space-between;
    margin-top: -25px; }
  .AuthorCard-wrapperGroup {
    color: #38675B;
    background-color: #ffffff;
    font-size: 16px;
    border-radius: 25px;
    padding: 15px 15px 15px 0;
    width: 49.3%;
    margin: 0 0 20px 0;
    min-height: 255px;
    max-height: 255px;
    float: left; }
    .AuthorCard-wrapperGroup:last-child {
      float: right; }
  .AuthorCard-infoGroup {
    width: 65%;
    margin-left: 35%;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    padding-left: 15px; }
  .AuthorCard-photoSideGroup {
    height: 255px;
    width: 175px;
    margin-top: -15px;
    float: left; }
  .AuthorCard-imgLeftGroup {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .AuthorCard-moreButtonGroup {
    padding-left: 15px; } }

@media not all and (min-resolution: 0.001dpcm) {
  .AuthorCard-info {
    padding-left: 35px; }
  .AuthorCard-infoGroup {
    padding-left: 25px; } }
