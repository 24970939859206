.TodosList {
  width: 100%;
  padding: 15px;
  margin: 0;
  display: block;
  border-radius: 24px;
  background-color: #fff;
  position: relative; }
  .TodosList .input-group {
    align-items: center; }
  .TodosList-currency {
    position: relative;
    left: -30px;
    z-index: 10;
    width: 0; }
  .TodosList .TodosList-label {
    width: auto;
    margin-right: 15px;
    font-size: 18px;
    font-weight: bold;
    color: #3C3C3B; }
  .TodosList .TodosList-input--onboarding {
    flex: 0;
    width: 170px;
    max-width: calc(100% - 80px);
    min-width: 170px; }
  .TodosList .TodosList-input--currency {
    padding-right: 37px; }
  .TodosList .TodosList-input--long {
    width: 100%;
    flex-grow: 1; }

.TodosList-item {
  display: block;
  padding: 5px 50px 5px 15px;
  color: #3C3C3B;
  font-size: 18px;
  line-height: 30px; }

.TodosList-addNew {
  color: #E8452C;
  cursor: pointer;
  height: 38px;
  background-color: white;
  border-radius: 5px;
  border: 1px dashed rgba(60, 60, 59, 0.16);
  text-align: left;
  padding: 2px 10px; }
  .TodosList-addNew:hover {
    text-decoration: none; }

.TodosList-remove {
  background-color: #E8452C;
  color: white;
  display: inline-flex;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -45px; }

.TodosList--onboarding .TodosList-item {
  display: flex;
  align-items: center;
  justify-content: center; }

.TodosList--onboarding .TodosList-input {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.TodosList--onboarding .select-input .select-input__control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: none; }

.TodosList--onboarding .input-group {
  align-items: center; }

.TodosList--onboarding .TodosList-label {
  width: 150px; }

.TodosList--long .form-group {
  width: 100%; }

@media (max-width: 767px) {
  .TodosList-item {
    padding: 5px 35px 5px 5px; }
  .TodosList--onboarding .input-group {
    justify-content: center; } }
