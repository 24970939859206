.Promo-header {
  padding: 50px 20px 30px;
  text-align: center;
  max-width: 725px;
  margin: auto; }

.Promo-form {
  padding: 15px 0 15px 48px; }

.Promo-spacing--sm {
  height: 18px; }

.Promo-imageBox {
  display: flex;
  align-items: flex-end;
  justify-content: center; }

.Promo-bottom {
  display: flex; }

.Promo-left {
  width: 45%;
  display: flex;
  align-items: center; }

.Promo-right {
  width: 55%; }

.Promo .has-feedback label ~ .form-control-feedback {
  top: -45px;
  right: 30px;
  height: 0;
  width: 0;
  float: right;
  position: relative;
  background: #ffffff;
  border-radius: 23px;
  height: auto;
  margin: auto;
  display: block;
  padding: 20px; }

.Promo-boxContainerMob {
  background: #ffffff;
  border-radius: 23px;
  height: auto;
  margin: auto;
  display: block;
  padding: 40px; }

.Promo-newsletter {
  padding-bottom: 100px;
  position: relative; }

.Promo-newsletterContent {
  max-width: calc(100vw - 30px);
  margin: auto; }

.Promo-newsletterInput {
  font-size: 16px;
  color: #7e7e7b;
  height: 48px;
  border: 2px solid #dcd4ce;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 20px; }

.Promo-inputGroup {
  margin-top: 36px;
  margin-bottom: 26px; }

.Promo-newsletterButton {
  border: 2px solid #dcd4ce;
  border-left-color: #dcd4ce;
  border-left-style: solid;
  border-left-width: 2px;
  border-left: none;
  color: #E8452C;
  font-weight: bold;
  background-color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0px 20px;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important; }
  .Promo-newsletterButton:hover, .Promo-newsletterButton:focus {
    color: #E8452C;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
    border-color: #dcd4ce;
    border-left-color: #dcd4ce; }

.Promo-newsletterLabel {
  position: relative;
  padding-left: 27px; }

@media (max-width: 767px) {
  .Promo-bottom {
    flex-wrap: wrap; }
  .Promo-right, .Promo-left {
    width: 100%; }
  .Promo-left {
    margin-bottom: 20px; }
  .Promo-form {
    padding: 15px 20px; } }
