.BasicParameters-slider {
  padding: 50px 150px;
  background-color: white;
  box-shadow: 4px 4px 0px rgba(60, 60, 59, 0.16);
  border-radius: 24px; }

.BasicParameters-count {
  font-size: 13px;
  color: #000000;
  background-color: white;
  border-radius: 49px;
  border: 1px solid #DBD0C9;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 241px;
  height: 30px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 16px; }
  .BasicParameters-count:hover {
    background-color: #f7f7f7; }

.BasicParameters-info {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 15px rgba(60, 60, 59, 0.16);
  padding: 15px 0;
  width: 100%; }

.BasicParameters-infoContent {
  max-width: 1140px;
  width: 100%;
  margin: auto; }

.BasicParameters-spacing--xl {
  height: 170px; }

.BasicParameters-spacing--lg {
  height: 64px; }

.BasicParameters-spacing--md {
  height: 52px; }

.BasicParameters-spacing--sm {
  height: 28px; }

.BasicParameters-spacing--xs {
  height: 6px; }

@media (max-width: 767px) {
  .BasicParameters-slider {
    padding: 50px 10px; } }
