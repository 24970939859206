.AuthorsScreen-checkbox input {
  display: none; }

.AuthorsScreen-checkbox label {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  padding-left: 0; }
  .AuthorsScreen-checkbox label:hover {
    color: #cb2e16; }

.AuthorsScreen-checkbox.checked label {
  color: #E8452C; }

.AuthorsScreen-selector {
  width: 240px; }

.AuthorsScreen-spacing--lg {
  height: 60px; }

.AuthorsScreen-spacing--sm {
  height: 30px; }

.AuthorsScreen-spacing--md {
  height: 25px; }

@media (max-width: 767px) {
  .AuthorsScreen-selector {
    width: 100%;
    margin-top: 25px; } }
